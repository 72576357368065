/* eslint-disable */
import React, { useEffect, useState } from "react";
import RequestLst from ".././data/gxList.json";
import type { RequestForm } from "../data/types";
import styles from "./css/MainGX.module.css";
import $ from 'jquery'
import jQuery from 'jquery';

const ListGxbaby = (props: {
  open: any;
  close: any;  
  getName: any;
  modalId: any;
  jdata:any | null;
  table_container_height: any;
}) => {

  const [disease, setDisease] = useState<RequestForm[]>([]);
  const [resultLst, setResultLst] = useState("");

  const [userName, setUserName] = useState();
  const [birth, setBirth] = useState();
  const [cartNo, setCartNo] = useState();
  const [vendorName, setVendorName] = useState();
  const [memo, setMemo] = useState();
  const [gender, setGenger] = useState();
  const [pickType, setPickType] = useState();
  const [age, setAge] = useState();
  const [picday, setPickDay] = useState();
  const [lastday, setLastDay] = useState();
  const [printday, setPrintDay] = useState();

  const [mngGrp, setMngGrp] = useState();
  const [plcNum, setPlcNum] = useState();
  const [createUser, setCreateUser] = useState();


  //info_box_wrap 높이 저장공간
  const [info_box_wrap_height, setinfo_box_wrap_height] = useState(0);
  
  useEffect(() => {
    
    const allList : any = JSON.stringify(props.jdata.result_viewr_list);
    const allList2 : any = JSON.stringify(props.jdata);
    
    if(allList != undefined){
      const obj = JSON.parse(allList);
      setDisease(obj);

      if(allList2 != undefined){
      const obj2 = JSON.parse(allList2);
      
      var date : any = new Date();
      var year : any = date.getFullYear();

      var age : any = "";

      if(obj2.birth.length < 7 && obj2.birth.length > 0) {

        var myyear1 = obj2.birth.substr(0, 1);
        if(myyear1 == "0"){

          var year = date.getFullYear();
          var month = (date.getMonth() + 1);
          var day = date.getDate();
          if (month < 10) month = '0' + month;
          if (day < 10) day = '0' + day;
          var monthDay = month + day;
          let birth2 : any = "20" + obj2.birth;
          let birth3 : any = birth2;
          var birthdayy = birth3.substr(0, 4);
          var birthdaymd = birth3.substr(4, 4);
          age = monthDay < birthdaymd ? year - birthdayy - 1: year - birthdayy;
          
        }else{
          var year = date.getFullYear();
          var month = (date.getMonth() + 1);
          var day = date.getDate();
          if (month < 10) month = '0' + month;
          if (day < 10) day = '0' + day;
          var monthDay = month + day;
          let birth2 : any = "19" + obj2.birth;
          let birth3 : any = birth2;
          var birthdayy = birth3.substr(0, 4);
          var birthdaymd = birth3.substr(4, 4);
          age = monthDay < birthdaymd ? year - birthdayy - 1: year - birthdayy;
        }
      } else if(obj2.birth.length > 7) {

        var year = date.getFullYear();
        var month = (date.getMonth() + 1);
        var day = date.getDate();
        if (month < 10) month = '0' + month;
        if (day < 10) day = '0' + day;
        var monthDay = month + day;
        let birth2 : any = obj2.birth.replace('-', '').replace('-', '');
        var birthdayy = birth2.substr(0, 4);
        var birthdaymd = birth2.substr(4, 4);
        age = monthDay < birthdaymd ? year - birthdayy - 1: year - birthdayy;

      }else {
        age = "0"
      }

     //console.log(age);
    
       setResultLst(allList2);  
       setUserName(obj2.user_name);
       setBirth(obj2.birth);
       setCartNo(obj2.chart_no);
       setVendorName(obj2.vendor_name);
       setMemo(obj2.memo);
       setGenger(obj2.gender);
       setPickType(obj2.pick_type);
       setAge(age);
       setPickDay(obj2.pick_day);
       setLastDay(obj2.review_date);
       setPrintDay(obj2.print_date);
       setMngGrp(obj2.mng_grp);
       setPlcNum(obj2.plc_num);
       setCreateUser(obj2.create_user);
      
      //console.log(allList);
      }
    }
  },[props]);




    //질병 정보
    const getDisease= async () => {
      const req = disease;
      const initData: any = req.map((it: RequestForm) => {
        
        return {
          id: it.idx,
          disease: it.disease,
          genotype : it.genotype,
          grp : it.grp,
          prevalence : it.prevalence,
          pick_type : it.pick_type,
          state : it.state,
          gubun : it.gubun,
          marker : it.marker
        };
      });
      setDisease(initData);
    };
  
  const { open, close, getName, modalId, table_container_height } = props;

  
  const [text, setText] = useState("");
  const [targetId, setTargetId] = useState(0);

  const saveEdit = (e: any) => {
    setText(e.target.value);
    setTargetId(modalId);
  };
  const sendName = () => {
    getName(text, targetId);
    //console.log(text, targetId);
  };

  if (!open) {
    return null;
  }

  //info_box_wrap 높이 구하기
  const info_box_wrap = (element: any) => {
    if (element) {
      setinfo_box_wrap_height(element.getBoundingClientRect().height);
      // console.log(info_box_wrap_height);
    }
  }

  let table_container2_height = table_container_height - (info_box_wrap_height);
  // console.log(table_container2_height)

  //table_container2 높이 자동 조정
  const table_container2 = (element: any) => {
    if (element) {
      $("#table_container2").height(table_container2_height);
    }
  }

  console.log("disease",disease)
  return (
    <>
      <div className={styles.table_box2}>
        <p className={`${styles.table_tit1} ${styles.table_tit2}`}>수진자 정보</p>

        <div ref={info_box_wrap}>

          <div className={styles.info_box}>
            <p className={styles.info_name}>{userName}<span>만 {age}세 / {gender}</span></p>

            <table className={styles.info_table}>
              <colgroup>
                <col width="11%"/>
                <col width="21%"/>
                <col width="13%"/>
                <col width="22%"/>
                <col width="11%"/>
                <col width="22%"/>
              </colgroup>
              <thead>
                <tr>
                  <th>검체종류</th>
                  <td>{pickType}</td>
                  <th>생년월일</th>
                  <td>{birth}</td>
                  <th>등록번호</th>
                  <td>{cartNo}</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>거래처명</th>
                  <td>{vendorName}</td>
                  <th>담당자 번호</th>
                  <td>-</td>
                  <th>관리그룹</th>
                  <td>{mngGrp}</td>
                </tr>
                <tr>
                  <th>병리번호</th>
                  <td>{plcNum}</td>
                  <th>팩스번호</th>
                  <td>6949-3340</td>
                  <th>등록자명</th>
                  <td>{createUser}</td>
                </tr>
                <tr>
                  <th>기타메모</th>
                  <td colSpan={5}>{memo}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className={styles.info_table_box}>
            <table className={`${styles.info_table} ${styles.info_table_etc}`}>
              <colgroup>
                <col width="8%"/>
                <col width="25%"/>
                <col width="9%"/>
                <col width="25%"/>
                <col width="8%"/>
                <col width="25%"/>
              </colgroup>
              <tbody>
                <tr>
                  <th>시작일시</th>
                  <td>{picday}</td>
                  <th>최종보고</th>
                  <td>{lastday}</td>
                  <th>출력일시</th>
                  <td>{printday}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        
        <div className={styles.table_container2} id="table_container2" ref={table_container2}>
          <table className={`${styles.listGx} ${styles.listGxbaby}`}>
            <thead>
              <tr>
                <th>검사코드</th>
                <th>검사명</th>
                <th>상태</th>
                <th>검사결과01</th>
                <th>검사결과02</th>
                <th>L/A/H</th>
                <th>검체명</th>
              </tr>
            </thead>
            <tbody>
            {
                disease.map((it, i) => (
                  <tr key={i}>
                    <td>{it.marker}</td>
                    {
                      it.gubun == 'product' ? <td className={styles.product}>{it.disease}</td>
                    : it.gubun == 'disease' ? <td className={styles.disease}>{it.disease}</td>
                    : <td className={styles.nameEtc} title={`${it.disease}`}>{it.disease}</td>
                    }
                    {/* 보류: hold / 시작: start / 의뢰: request / 중간: middle / 최종: final  */}
                    {it.state == "80" ? (
                      <td className={`${styles.hold} ${styles.hide_letter}`} >
                        {it.state}
                      </td>
                    ) : it.state == "20"? (
                      <td className={`${styles.start} ${styles.hide_letter}`}>
                        {it.state}
                      </td>
                    ): it.state == "30"  ? (
                      <td className={`${styles.start} ${styles.hide_letter}`}>
                        {it.state}
                      </td>
                    ): it.state ==  "40" ? (
                      <td className={`${styles.start} ${styles.hide_letter}`}>
                        {it.state}
                      </td>
                    ) : it.state == "10" ? (
                      <td className={`${styles.request} ${styles.hide_letter}`}>
                        {it.state}
                      </td>
                    ) : it.state == "50"? (
                      <td className={`${styles.start} ${styles.hide_letter}`}>
                        {it.state}
                      </td>
                     ) : it.state == "60" ? (
                      <td className={`${styles.middle} ${styles.hide_letter}`}>
                        {it.state}
                      </td>
                     ) : it.state == "65" ? (
                       <td className={`${styles.final} ${styles.hide_letter}`}>
                        {it.state}
                       </td>
                    ) : it.state ==  "70"  ? (
                      <td className={`${styles.final} ${styles.hide_letter}`}>
                       {it.state}
                      </td>
                     ) : it.state == "90" ? (
                      <td className={`${styles.final} ${styles.hide_letter}`}>
                       {it.state}
                      </td>
                    ) : it.state == "100" ? (
                      <td className={`${styles.final} ${styles.hide_letter}`}>
                      {it.state}
                      </td>
                    ) : (
                        <td className={` ${styles.hide_letter}`}>
                          {it.state}
                        </td>
                        )}
                    <td>{it.genotype}</td>
                    <td>{it.prevalence}</td>
                    {/* L: styles.L / A: styles.A / H: styles.H */}
                    {
                        it.grp == '1' ? <td className={styles.L}>L</td>
                      : it.grp == '2' ? <td className={styles.A}>A</td>
                      : it.grp == '3' ? <td className={styles.H}>H</td>
                      : <td></td>
                    }
                    <td>{it.pick_type}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ListGxbaby;

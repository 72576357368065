/* eslint-disable */

import { pdf } from "@react-pdf/renderer";
import $ from "jquery";
import printJS from "print-js";
import { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { writeFile } from "xlsx";
import { default as apiModule, default as axios } from "../common/wrapper/axios";
import type { Report_results, ResultData } from "../data/resultList";
import Loading from "./components/Loading";
import NoResult from "./components/NoResult";
import ProgressBar from "./components/ProgressBar";
import ReportDocument from "./components/report/ReportDocument";
import ReportDocument_typeB from "./components/report/ReportDocument_typeB";
import ReportDocument_typeY from "./components/report/ReportDocument_typeY";
import TestDoc from "./components/report/TestDoc";
import SelectResult from "./components/SelectResult";
import styles from "./css/MainGX.module.css";
import ListGx from "./ListGx";

function Option() {
  const [jdata, setJdata] = useState([]);
  const [cntdata, setCntdata] = useState([]);

  var token = "bearer " + sessionStorage.getItem("auth");
  const intialValues = { startDate: "", userName: "", startNum: "", endDate: "", barcode: "", endNum: "", size: 100000 };
  const [formValues, setFormValues] = useState(intialValues);
  const [checkForm, setChekForm] = useState("");
  const [checkForm2, setChekForm2] = useState("");

  // input date 오늘날짜
  useEffect(() => {
    let today: any = new Date();
    today = today.toISOString().slice(0, 10);

    $("#startDate").val(today);
    $("#endDate").val(today);
    intialValues.startDate = today;
    intialValues.endDate = today;
  }, []);

  const [idList, setIdList] = useState<number[]>([]);

  const getIDList = (arr: any) => {
    setIdList(arr);
  };

  const updateDataObj = { status_gbn: 70, print_state: "done" };

  const changeStateFun = () => {
    idList.map(function (a) {
      axios
        .put(`/experiment/${a}`, updateDataObj)
        //성공시 then 실행
        .then(function (response) {
          //console.log("성공");
        })
        //실패 시 catch 실행
        .catch(function (error) {
          // console.log(error+"에러");
        });
    });
  };

  const onChangeFun = (e: any) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };
  // console.log(formValues);

  //ListGxbaby open,close 함수
  const [openModal, setOpenModal] = useState(false);
  //ListGxbabyNone open,close 함수
  const [none, setNone] = useState(true);
  //선택된 행 active
  let [active, setActive] = useState(-1);
  //로딩
  const [loading, setLoading] = useState(false);
  const [progressBar, setProgressBar] = useState(0);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.code === "Enter") {
      getCheckboxValue();
    }
  };

  const getCheckboxValue = () => {
    setActive(-1);
    setNone(true);
    setOpenModal(false);

    //로딩창 뜨게
    setLoading(true);

    setChekForm("");
    setChekForm2("");
    // 선택된 목록 가져오기
    const query = 'input[name="opt"]:checked';
    const selectedEls = document.querySelectorAll(query);

    const query2 = 'input[name="opt2"]:checked';
    const selectedEls2 = document.querySelectorAll(query2);

    let result = "";
    let result2 = "";
    // 선택된 목록에서 value 찾기
    selectedEls.forEach((el: any) => {
      result += el.value + ",";
      setChekForm("");
      var re1 = result.substring(0, result.length - 1);
      var re2 = result.slice(0, -1);
      setChekForm(re2);
    });

    selectedEls2.forEach((el2: any) => {
      result2 += el2.value + ",";
      setChekForm2("");
      var re3 = result2.substring(0, result2.length - 1);
      var re4 = result2.slice(0, -1);
      setChekForm2(re4);
    });

    setTimeout(function () {
      $(".listGx").show();
      $(".selectList").hide();

      var link: any = document.getElementById("go");
      link.click();
    }, 1000);
  };

  let result1 = formValues.startDate.substr(0, 4);

  let result2 = Number(result1);

  const getList = async () => {
    const dd = await apiModule
      .get(
        `/experiment/getList?userName=${formValues.userName}&chartNo=${formValues.barcode}&startNo=${formValues.startNum}&endNo=${formValues.endNum}&startDate=${formValues.startDate}&endDate=${formValues.endDate}&size=${formValues.size}&page=0&sort=jubsuDate,desc&state=${checkForm}&printState=${checkForm2}`
      )
      //성공시 then 실행
      .then(function (response) {
        setJdata(response.data.list);
        setCntdata(response.data.statistics);
        //console.log(response);
        setLoading(false);
      })
      //실패 시 catch 실행
      .catch(function (error) {
        //console.log(error);
      });
  };

  const [checkedData, setCheckedData] = useState<ResultData[]>([]);
  const [checkedData2, setCheckedData2] = useState<Report_results[]>([]);
  const getReport = (reportData: ResultData[], resultData: Report_results[]) => {
    setCheckedData((prev) => {
      // console.log("이전 값 : ",prev)
      return reportData
    }
    );
    setCheckedData2(() => resultData);
  };

  const arr = new Array();
  const validVendors = ["강남지인병원", "의료법인 문원의료재단", "한빛영상의학과의원", "메디케어의원", "다온 헬스케어 의원", "서울척병원"];

  const docPreview = async () => {
    const hasInProgress = checkedData.some(item => item.state !== 65 && item.state !== 70);
    if (hasInProgress) {
      return alert("[최종] 상태의 리스트만 미리보기가 가능합니다.");
    } else {
      let doc : any = "";
      for (let i = 0; i < checkedData.length; i++) {
        const asPdf = pdf([] as any);
        const chkData = checkedData[i];
        const chkData2 = checkedData2[i];
        // console.log("chkData : ",chkData)
        // console.log("chkData2 : ",chkData2)
        // console.log("chkData2 타입:", Array.isArray(chkData2) ? "배열" : "단일 객체");
        // 종합 + 항목
        if (validVendors.includes(chkData.vendor_name ?? "")) {
          doc = <ReportDocument_typeB chkData={[chkData]} chkData2={chkData2} />;
        }
        // 메인 + 종합 + 항목
        else if(chkData.vendor_name == "남천병원") {
          doc = <ReportDocument_typeB chkData={[chkData]} chkData2={chkData2} page0={true}/>;
        }
        // 요청한 항목만
        else if(chkData.vendor_name == "의료법인인봉의료재단 영등포병원") {
          doc = <ReportDocument_typeY chkData={[chkData]} chkData2={chkData2} />;
        }
        // 전체 페이지
        else {
          doc = <ReportDocument chkData={[chkData]} chkData2={chkData2} />;
        }
        asPdf.updateContainer(doc);
        const blob = await asPdf.toBlob();
        const fileURL = URL.createObjectURL(blob) + "#toolbar=0&navpanes=0&scrollbar=0";
        window.open(fileURL);
        URL.revokeObjectURL(fileURL);
      }
    }
  };

  const fileSave = async () => {
    if(checkedData.length < 1) return false;
    
    if (typeof window !== 'undefined' && 'caches' in window) { // 상용 및 로컬은 캐시를 사용
      const cache = await caches.open('checkedCache');

      await cache.put('checkedData', new Response(JSON.stringify(checkedData)));
      await cache.put('checkedData2', new Response(JSON.stringify(checkedData2)));
      await cache.put('idList', new Response(JSON.stringify(idList)));
    } else { // 개발계 위한 세션스토리지 사용
      sessionStorage.setItem("checkedData", JSON.stringify(checkedData));
      sessionStorage.setItem("checkedData2", JSON.stringify(checkedData2));
      sessionStorage.setItem("idList", JSON.stringify(idList));
    }
    window.open("./?isNew=true", "downloadNewWindow",  "width=400, height=240");
  };
  
  const excelSave= async () => {
    if(checkedData.length < 1) return false;

    const table : any = document.getElementById('exList');
    let rows = table.getElementsByTagName('tr');
    let selectedData: any[][] = [];
    for (let i = 0; i < rows.length; i++) {
      let checkbox = rows[i].querySelector('input[type="checkbox"]');
      if (checkbox && checkbox.checked) {
        let rowData: any[] = [];
        let cells = rows[i].querySelectorAll('td');
        cells.forEach((cell: { textContent: any; }) => {
          rowData.push(cell.textContent);
        });
        selectedData.push(rowData);
      }
    }

    const excelTableHead = [
        ['','보고서', '출력', '응급', '접수일자', '등록번호', '수진자명', '생년월일', '성별',
          '상태', '거래처명', '의뢰코드', '의뢰항목명', '부속코드', '부속', '검체', '출력일', '메모']
    ];
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet([]);

    XLSX.utils.sheet_add_aoa(ws, excelTableHead, { origin: "A1" });
    XLSX.utils.sheet_add_json(ws, selectedData, { origin: "A2", skipHeader: true });
    XLSX.utils.book_append_sheet(wb , ws, "tb3NAME");

    writeFile(wb, new Date().toISOString().slice(0, 10) + ".xlsx");
  };

  //다중 출력
  // const PrintDoc = () => {
  //   const rows: any = [];
  //   for (let i = 0; i < checkedData.length; i++) {
  //     rows.push(<TestDoc chkData={checkedData} chkData2={checkedData2[i]} />);
  //   }
  //   return <>{rows}</>;
  // };

  // 인쇄 작업 시작
  const pdfPrint = async () => {
    const pdfData: ResultData[] = [];

    idList.forEach((id) => {
      let item = checkedData.find((data) => data.idx === id);
      if (item) {
        pdfData.push(item);
      }
    });
    
    console.log("pdfData",pdfData)
    const blob = await pdf(<TestDoc chkData={pdfData}/>).toBlob();
    const blobURL = URL.createObjectURL(blob);
    printJS(blobURL);
    URL.revokeObjectURL(blobURL);
  };
  return (
    <>
      <div className={styles.option_box}>
        <div className={styles.option_box_cont1}>
          <ul>
            <li>
              <span>시작일</span>
              <input type="date" onChange={onChangeFun} id="startDate" name="startDate" min={"2023-01-01"}></input>
            </li>
            <li>
              <span>수진자명</span>
              <input type="text" onChange={onChangeFun} name="userName" onKeyDown={handleKeyDown}></input>
            </li>
            <li>
              <span>시작번호</span>
              <input type="text" onChange={onChangeFun} name="startNum" onKeyDown={handleKeyDown}></input>
            </li>
            <li>
              <span>종료일</span>
              <input type="date" onChange={onChangeFun} id="endDate" name="endDate" min={"2023-01-01"}></input>
            </li>
            <li>
              <span>등록번호</span>
              <input type="text" onChange={onChangeFun} name="barcode" onKeyDown={handleKeyDown}></input>
            </li>
            <li>
              <span>종료번호</span>
              <input type="text" onChange={onChangeFun} name="endNum" onKeyDown={handleKeyDown}></input>
            </li>
          </ul>
        </div>
        <div className={styles.option_box_cont2}>
          <ul>
            <li className={styles.chk_txt}>출력</li>
            <li className={styles.chk_boxes}>
              <input type="checkbox" value="empty" id="test" className={styles.chk_option} name="opt2"></input>
              <label htmlFor="test" className={styles.chk_Label}></label>
              <label htmlFor="test" className={`${styles.chk_Label} ${styles.chk_LabelTxt}`}>
                검사
              </label>
            </li>
            <li className={styles.chk_boxes}>
              <input type="checkbox" value="wait" id="wait" className={styles.chk_option} name="opt2"></input>
              <label htmlFor="wait" className={styles.chk_Label}></label>
              <label htmlFor="wait" className={`${styles.chk_Label} ${styles.chk_LabelTxt}`}>
                대기
              </label>
            </li>
            <li className={styles.chk_boxes}>
              <input type="checkbox" value="done" id="done" className={styles.chk_option} name="opt2"></input>
              <label htmlFor="done" className={styles.chk_Label}></label>
              <label htmlFor="done" className={`${styles.chk_Label} ${styles.chk_LabelTxt}`}>
                완료
              </label>
            </li>
          </ul>
          <ul>
            <li className={styles.chk_txt}>상태</li>
            <li className={styles.chk_boxes}>
              <input type="checkbox" value="defer" id="hold" className={styles.chk_option} name="opt"></input>
              <label htmlFor="hold" className={styles.chk_Label}></label>
              <label htmlFor="hold" className={`${styles.chk_Label} ${styles.chk_LabelTxt}`}>
                보류
              </label>
            </li>
            <li className={styles.chk_boxes}>
              <input type="checkbox" value="start" id="start" className={styles.chk_option} name="opt"></input>
              <label htmlFor="start" className={styles.chk_Label}></label>
              <label htmlFor="start" className={`${styles.chk_Label} ${styles.chk_LabelTxt}`}>
                시작
              </label>
            </li>
            <li className={styles.chk_boxes}>
              <input type="checkbox" value="receive" id="request" className={styles.chk_option} name="opt"></input>
              <label htmlFor="request" className={styles.chk_Label}></label>
              <label htmlFor="request" className={`${styles.chk_Label} ${styles.chk_LabelTxt}`}>
                의뢰
              </label>
            </li>
            <li className={styles.chk_boxes}>
              <input type="checkbox" value="analysis" id="middle" className={styles.chk_option} name="opt"></input>
              <label htmlFor="middle" className={styles.chk_Label}></label>
              <label htmlFor="middle" className={`${styles.chk_Label} ${styles.chk_LabelTxt}`}>
                중간
              </label>
            </li>
            <li className={styles.chk_boxes}>
              <input type="checkbox" value="done" id="final" className={styles.chk_option} name="opt"></input>
              <label htmlFor="final" className={styles.chk_Label}></label>
              <label htmlFor="final" className={`${styles.chk_Label} ${styles.chk_LabelTxt}`}>
                최종
              </label>
            </li>
          </ul>
          <ul>
            <li className={styles.chk_txt}>언어</li>
            <li className={styles.chk_boxes}>
              <input type="checkbox" id="korean" className={styles.chk_option} name="" defaultChecked></input>
              <label htmlFor="korean" className={styles.chk_Label}></label>
              <label htmlFor="korean" className={`${styles.chk_Label} ${styles.chk_LabelTxt}`}>
                국문
              </label>
            </li>
            {/* 영문 체크박스는 hidden처리 */}
            <li className={styles.chk_boxes}>
              <input type="checkbox" id="english" className={styles.chk_option} name="opt" disabled></input>
              <label htmlFor="english" className={styles.chk_Label} style={{ opacity: "0.5" }}></label>
              <label htmlFor="english" className={`${styles.chk_Label} ${styles.chk_LabelTxt}`} style={{ opacity: "0.5" }}>
                영문
              </label>
            </li>
          </ul>
        </div>
        <div className={styles.option_box_cont3}>
          <button
            type="button"
            className={styles.option_box_btn}
            onClick={() => {
              getCheckboxValue();
            }}
            onKeyDown={handleKeyDown}
          >
            검색하기
          </button>
          <button
            type="button"
            style={{ display: "none" }}
            id="go"
            onClick={() => {
              getList();
            }}
          ></button>
          <div className={styles.btn_box}>
            <button type="button" onClick={docPreview}>
              {" "}
              미리보기{" "}
            </button>
            <button
              type="button"
              onClick={() => {
                if (checkedData.some(item => item.state != null && item.state < 65)) {
                  return alert("[최종] 상태의 리스트만 미리보기가 가능합니다.");
                } else {
                  changeStateFun();
                  pdfPrint();
                }
              }}
            >
              인쇄하기
            </button>
            <button
              type="button"
              onClick={() => {
                if (checkedData.some(item => item.state != null && item.state < 65)) {
                  return alert("[최종] 상태의 리스트만 미리보기가 가능합니다.");
                } else {
                  changeStateFun();
                  fileSave();
                }
              }}
            >
              {" "}
              다운로드{" "}
            </button>
            <button
                type="button"
                onClick={() => excelSave()}
            >엑셀변환</button>
          </div>
        </div>
      </div>

      {/* <PDFViewer style={{width: "100%"}}>
        <TestDoc chkData={checkedData} chkData2={checkedData2}/>
      </PDFViewer> */}

      {/* 연습 */}
      {/* <PDFViewer style={{width: "100%",height: "80vh"}}>
        <Microbiome/>
      </PDFViewer> */}

      <div className="selectList">
        <SelectResult/>
      </div>
      <div className="noList" style={{display: "none" }}>
        <NoResult />
      </div>
      <div className="listGx" style={{ display: "none" }}>
        <ListGx
          getReport={getReport}
          getIDList={getIDList}
          props={jdata}
          cnt={cntdata}
          openModal={openModal}
          setOpenModal={setOpenModal}
          none={none}
          setNone={setNone}
          active={active}
          setActive={setActive}
          loading={loading}
          setLoading={setLoading}
        />
      </div>

      {/* loading */}
      {loading == true ? <Loading /> : null}
      {/* progressing */}
      {(progressBar <= 100 && progressBar > 0) ? <ProgressBar progressBar={progressBar} /> : null}
    </>
  );
}

export default Option;

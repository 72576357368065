import ProgressBar from "./ProgressBar";
import ReportDocument_typeB from "./report/ReportDocument_typeB";
import ReportDocument from "./report/ReportDocument";
import saveAs from "file-saver";
import {pdf} from "@react-pdf/renderer";
import {useEffect, useState} from "react";
import styles from "../css/MainGX.module.css";
import ReportDocument_typeY from "./report/ReportDocument_typeY";

let doc: any = "";
const asPdf = pdf([] as any);

function closePopup() {
    if (typeof window !== 'undefined' && 'caches' in window) { // 상용 및 로컬은 캐시를 사용
        caches.delete('checkedCache');
    } else { // 개발계 위한 세션스토리지 사용
        sessionStorage.removeItem("checkedData");
        sessionStorage.removeItem("checkedData2");
        sessionStorage.removeItem("idList");
    }

    return window.close();
}
const validVendors = ["강남지인병원", "의료법인 문원의료재단", "한빛영상의학과의원", "메디케어의원", "다온 헬스케어 의원", "서울척병원"];

const DownloadPopup = () => {
    const [percent, setPercent] = useState(0);
    const [isCompleted, setIsCompleted] = useState(false);

    useEffect(() => {
        try {
            const generateAndDownloadPdf = async () => {
                setPercent(1);

                let checkedData: any[] = [];
                let checkedData2: any[] = [];
                let idList: any[] = [];

                if (typeof window !== 'undefined' && 'caches' in window) {
                    const checkedDataCache = await caches.match("checkedData");
                    const checkedData2Cache = await caches.match("checkedData2");
                    const idListCache = await caches.match("idList");
                    if (!checkedDataCache || !checkedData2Cache || !idListCache) {
                        return false;
                    }
                
                    checkedData = await checkedDataCache.json();
                    checkedData2 = await checkedData2Cache.json();
                    idList = await idListCache.json();
                } else {
                    checkedData = JSON.parse(sessionStorage.getItem("checkedData") || "[]");
                    checkedData2 = JSON.parse(sessionStorage.getItem("checkedData2") || "[]");
                    idList = JSON.parse(sessionStorage.getItem("idList") || "[]");
                }

                let ratio = 100 / (checkedData.length * 2);
                let arr: any[] = [];
                for (let i = 0; i < checkedData.length; i++) {

                    const chkData = checkedData[i];
                    const chkData2 = checkedData2[i];
                    // 종합 + 항목
                    if (validVendors.includes(chkData.vendor_name ?? "")) {
                        doc = <ReportDocument_typeB chkData={[chkData]} chkData2={chkData2} />;
                    }
                    // 메인 + 종합 + 항목
                    else if(chkData.vendor_name === "남천병원") {
                        doc = <ReportDocument_typeB chkData={[chkData]} chkData2={chkData2} page0={true}/>;
                    }
                    // 요청한 항목만
                    else if(chkData.vendor_name === "의료법인인봉의료재단 영등포병원") {
                        doc = <ReportDocument_typeY chkData={[chkData]} chkData2={chkData2} />;
                    }
                    // 전체 페이지
                    else {
                        doc = <ReportDocument chkData={[chkData]} chkData2={chkData2} />;
                    }
                    const beforeDate: any = checkedData[i].pick_day;
                    const afterDate = beforeDate.replaceAll("-", "");
                    const filename = "" + checkedData[i].vendor_name + checkedData[i].product_name + "_"
                        + checkedData[i].chart_no + "_" + afterDate + "_" + checkedData[i].user_name;
                    asPdf.updateContainer(doc);
                    const blob = await asPdf.toBlob();
                    arr.push({idx: checkedData[i].idx, name: filename, blob: blob});
                    setPercent(Math.floor((i + 1) * ratio));
                }

                let ratioCnt = checkedData.length;
                let timerIndex: number = 0;
                let lastGenTime = new Date().getTime();
                const timerId = setInterval(() => {
                    const genTime = new Date().getTime();
                    if ((genTime - lastGenTime) < 1000) {
                        return;
                    }
                    lastGenTime = genTime;
                    const dataIdx = idList[timerIndex++];
                    const index = arr.findIndex((item) => item.idx === dataIdx);
                    if (index !== -1) {
                        arr.push(arr[index]);
                        saveAs(arr[index].blob, arr[index].name);
                    }
                    setPercent(Math.floor((ratioCnt++) * ratio));
                    if (timerIndex === idList.length) {
                        clearInterval(timerId);
                        setPercent(100);
                        setIsCompleted(true);
                        
                        if (typeof window !== 'undefined' && 'caches' in window) { // 상용 및 로컬은 캐시를 사용
                            caches.delete('checkedCache');
                        } else { // 개발계 위한 세션스토리지 사용
                            sessionStorage.removeItem("checkedData");
                            sessionStorage.removeItem("checkedData2");
                            sessionStorage.removeItem("idList");
                        }
                    }
                }, 100);
            }
            generateAndDownloadPdf();
        } catch (e) {
            alert(e);
            return closePopup();
        }
    }, []);

    return (
        <>
            {!isCompleted && <ProgressBar progressBar={percent}/>}
            {isCompleted &&
                <div className={styles.is_new_popup}>
                    <p>다운로드가 완료되었습니다.</p>
                    <button
                        className={styles.btn}
                        onClick={() => {
                            closePopup()
                        }}>
                        닫기
                    </button>
                </div>
            }
        </>
    );
}
export default DownloadPopup;
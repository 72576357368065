import React from "react";

import { Page, Document, View, Font, StyleSheet, Text, Image } from "@react-pdf/renderer";

import type { ResultData, Report_results} from "../../../data/resultList";

//나눔스퀘어
import acB from "./fonts/NanumSquare/NanumSquare_acB.ttf";
import acEB from "./fonts/NanumSquare/NanumSquare_acEB.ttf";
import acL from "./fonts/NanumSquare/NanumSquare_acL.ttf";
import acR from "./fonts/NanumSquare/NanumSquare_acR.ttf";
import B from "./fonts/NanumSquare/NanumSquareB.ttf";
import EB from "./fonts/NanumSquare/NanumSquareEB.ttf";
import L from "./fonts/NanumSquare/NanumSquareL.ttf";
import R from "./fonts/NanumSquare/NanumSquareR.ttf";

Font.register({
  family: "NanumSquare",
  fonts: [
    {
      src: acB,
      fontWeight: 500,
    },
    {
      src: acEB,
      fontWeight: 700,
    },
    {
      src: acL,
      fontWeight: 300,
    },
    {
      src: acB,
      fontWeight: 500,
    },
    {
      src: acR,
      fontWeight: 400,
    },
    {
      src: B,
      fontWeight: "bold",
    },
    {
      src: L,
      fontWeight: "light",
    },
    {
      src: R,
      fontWeight: "normal",
    },
    {
      src: EB,
      fontWeight: "ultrabold",
    },
  ],
});

// Font.registerHyphenationCallback((word) => Array.from(word).flatMap((char) => [char, ""]));

const styles = StyleSheet.create({
  //공통
  wrap: {
    width: "100%",
    height: "100%",
    position: "relative",
    padding: "24pt 48pt",
    fontFamily: "NanumSquare",
  },
  imgTxtBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  headlogo: {
    width: "60px",
    position: "absolute",
    top: "22pt",
    right: "44pt",
    padding: "4px",
  },
  bottomTxt: {
    fontSize: "9pt",
    color: "#595959",
    position: "absolute",
    left: "48pt",
    bottom: "24pt",
    letterSpacing: -0.6,
  },
  purpleboldTxt: {
    color: "#3428BB",
    fontWeight: "bold",
  },
  boltTxt: {
    fontWeight: 500,
  },

  //간지
  ganziImg: {
    position: "absolute",
    width: "106px",
    right: "48pt",
    bottom: "56pt",
  },
  ganzi_txt1: {
    marginTop: "200px",
    color: "#3428BB",
    fontSize: "32pt",
    fontWeight: 800,
    lineHeight: "1.5",
  },
  ganzi_txt2: {
    fontSize: "45pt",
  },

  //0_표지_앞면
  coverHead_logo: {
    width: "86px",
    position: "absolute",
    top: "26pt",
    right: "28pt",
  },
  coverHead_version2: {
    width: "86px",
    position: "absolute",
    top: "60pt",
    right: "28pt",
  },
  coverHead_box: {
    width: "74.5%",
    margin: "0 auto",
    marginLeft: "48px",
    // backgroundColor: "yellow"
  },
  coverHead_Txt1: {
    marginTop: "218px",
    fontWeight: "bold",
    color: "#3B3E5A",
    fontSize: "18pt",
    // letterSpacing: -0.1,
  },
  coverHead_Txt2: {
    fontWeight: "normal",
    color: "#323333",
    fontSize: "10pt",
    marginTop: "2px",
    // letterSpacing: -0.1,
  },
  coverHead_Box1: {
    backgroundColor: "#E9EFF4",
    borderRadius: "12px",
    margin: "8px auto 0",
    marginLeft: "48px",
    flexDirection: "column",
    padding: "7px 0 7px 24px",
    width: "62%",
  },
  coverHead_Box2: {
    flexDirection: "row",
    height: "16px",
    alignItems: "center",
    // backgroundColor: "green",
  },
  coverHead_Box2_TxtL: {
    fontWeight: 500,
    width: "15%",
    fontSize: "9pt",
    color: "#222",
    textAlign: "left",
    // backgroundColor: "yellow"
  },
  coverHead_Box2_TxtR: {
    fontWeight: "normal",
    width: "30%",
    marginRight: "8px",
    fontSize: "9pt",
    color: "#222",
    // backgroundColor: "orange"
  },
  coverHead_Img: {
    position: "absolute",
    width: "120%",
    bottom: "40px",
  },

  //1_전체결과요약
  pg2Img: {
    width: "46%",
    position: "absolute",
    top: "32px",
    right: "56px",
  },
  product_name: {
    marginTop: "120px",
    color: "#595959",
    fontWeight: 500,
    fontSize: "16.5pt",
    letterSpacing: -1,
  },
  product_tit: {
    marginTop: "8px",
    fontWeight: 700,
    color: "#3428BB",
    fontSize: "21pt",
    letterSpacing: -1,
  },
  tit_back1: {
    marginTop: "28px",
    marginBottom: "-38px",
    width: "240px",
    height: "14px",
    backgroundColor: "#E3E2FF",
    letterSpacing: "0.02pt",
  },
  side_box: {
    margin: "0 auto",
    width: "92%",
  },
  gh: {
    marginTop: "40px",
  },
  gh_half: {
    marginTop: "20px",
  },
  gh_img: {
    width: "14px",
  },
  gh_tit: {
    color: "#222",
    fontWeight: 500,
    fontSize: "11pt",
    margin: "0 0 0 6px",
    letterSpacing: -0.5,
  },
  gene_result_box: {
    position: "relative",
    overflow: "hidden",
    padding: "32px 40px 0",
    margin: "16px 0 0 0",
    width: "100%",
    backgroundColor: "#e9eff4",
    borderRadius: "16px",
    color: "#222",
  },
  g_r_t: {
    position: "absolute",
    top: "26px",
    left: "170px",
    display: "flex",
    flexDirection: "row",
    fontSize: "11pt",
    fontWeight: 600,
    color: "#222",
  },
  g_r_tR: {
    margin: "0 0 0 110px",
  },
  tatal_img: {
    position: "absolute",
    top: "32px",
    left: "40px",
    width: "110px",
  },
  rw_box: {
    margin: "6px 0 0 0",
    height: "318px",
  },
  rw_boxes: {
    padding: "0 0 0 70pt",
    margin: "10px 0 0 40px",
    backgroundColor: "#fff",
    width: "92%",
    height: "70px",
    borderRadius: "16px",
    fontSize: "10pt",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  rw_fi: {
    width: "25%",
    textAlign: "center",
    fontWeight: 500,
    fontSize: "12.5pt",
  },
  rw_fi_red: {
    color: "#F8463B",
  },
  rw_fi_yellow: {
    color: "#F99C11",
  },
  rw_fi_green: {
    color: "#14AB87",
  },
  rw_sec: {
    width: "75%",
    padding: "0 5pt",
    textAlign: "center",
  },
  result_all_box: {
    margin: "16px 0 0 0",
    overflow: "hidden",
    padding: "16px",
    border: "1px solid #3428BB",
    borderRadius: "16px",
  },
  result_all: {
    color: "#222",
    textAlign: "center",
    fontSize: "10pt",
    lineHeight: 1.5,
  },
  re_end: {
    margin: "10px 0 0 0",
    textAlign: "right",
    fontSize: "10pt",
  },

  //2_서비스소개
  serviceInfo2_Box1: {
    position: "relative",
    marginTop: "75pt",
    width: "100%",
    height: "60px",
  },
  serviceInfo2_Box1_Img: {
    width: "35%",
    position: "absolute",
    bottom: 0,
  },
  serviceInfo2_Box1_Txt: {
    fontSize: "13pt",
    color: "#595959",
    position: "absolute",
    left: "180px",
    bottom: "-2px",
    width: "30%",
    fontWeight: "normal",
  },
  serviceInfo2_Txt: {
    color: "#222",
    fontSize: "12pt",
    marginTop: "12px",
    lineHeight: 1.4,
    fontWeight: "normal",
  },
  serviceInfo2_Img: {
    width: "344px",
    margin: "30px auto",
  },
  serviceInfo2_Box2: {
    backgroundColor: "#E9EFF4",
    overflow: "hidden",
    borderRadius: "16px",
    padding: "20px",
  },
  serviceInfo2_Box3: {
    flexDirection: "row",
    height: "auto",
    overflow: "hidden",
  },
  serviceInfo2_Box3_Txt1: {
    textAlign: "justify",
    fontSize: "10pt",
    color: "#595959",
    width: "47%",
    height: "auto",
    lineHeight: 1.4,
    fontWeight: "normal",
  },
  serviceInfo2_Box3_Txt2: {
    textAlign: "justify",
    color: "#595959",
    fontSize: "10pt",
    width: "47%",
    height: "auto",
    marginLeft: "3%",
    lineHeight: 1.4,
    fontWeight: "normal",
  },
  serviceInfo2_Box4: {
    marginTop: "20px",
    marginLeft: "10px",
    alignItems: "center",
  },
  serviceInfo2_Box4_Img_box: {
    width: "10%",
  },
  serviceInfo2_Box4_Img: {
    width: "32px",
    margin: "-2px auto 0",
  },
  serviceInfo2_Box4_Txt: {
    fontSize: "9pt",
    fontWeight: "normal",
    color: "#595959",
    width: "88%",
    lineHeight: 1.4,
  },

  //2-1_서비스소개
  serviceInfo21_Txt1: {
    margin: "75pt 0 10pt",
    width: "100%",
    fontWeight: 500,
    fontSize: "26pt",
    color: "#222",
  },
  serviceInfo21_Txt2: {
    fontSize: "15pt",
    color: "#222",
    fontWeight: "normal",
  },
  serviceInfo21_Box1: {
    display: "flex",
    flexDirection: "column",
    borderTop: "3px",
    borderTopStyle: "solid",
    borderTopColor: "#3428BB",
    width: "93%",
    margin: "44px auto 0",
    overflow: "hidden",
    height: "auto",
  },
  serviceInfo21_Box2: {
    flexDirection: "row",
    position: "relative",
    alignItems: "center",
    width: "100%",
    height: "auto",
    padding: "28px",
    borderBottom: "1px",
    borderBottomColor: "#C9C9C9",
    borderBottomStyle: "solid",
  },
  serviceInfo21_Box3: {
    position: "relative",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    padding: "24px",
  },
  serviceInfo21_Box2_Img: {
    width: "32px",
  },
  serviceInfo21_Box2_Txt: {
    fontSize: "11pt",
    textAlign: "justify",
    marginLeft: "24px",
    marginRight: "16px",
    lineHeight: 1.6,
    fontWeight: "normal",
    color: "#222",
  },
  serviceInfo21_Img: {
    width: "75%",
    margin: "16px auto 0",
  },

  //3_유전자검사의 이해
  geneInfo3_Txt1: {
    margin: "75pt 0 10pt",
    width: "100%",
    fontSize: "28.5pt",
  },
  geneInfo3_Txt2: {
    fontSize: "16pt",
    fontWeight: 500,
    color: "#222",
    width: "30%",
    marginTop: "-10px",
  },
  geneInfo3_Img: {
    position: "absolute",
    width: "80px",
    bottom: "-140px",
    left: "0px",
  },
  tit_back: {
    position: "absolute",
    width: "100%",
    height: "14px",
    backgroundColor: "#E3E2FF",
    marginTop: "10px",
  },
  geneInfo3_Box1: {
    flexDirection: "column",
    display: "flex",
    position: "relative",
    marginTop: "36px",
  },
  geneInfo3_Box1_Img: {
    width: "32px",
    left: 0,
  },
  geneInfo3_Box2: {
    flexDirection: "row",
    display: "flex",
    marginTop: "10px",
  },
  geneInfo3_Box2_Txt1: {
    color: "#222",
    fontSize: "11pt",
    lineHeight: 1.4,
    fontWeight: "normal",
    width: "30%",
  },
  geneInfo3_Box2_Txt2: {
    fontWeight: "normal",
    fontSize: "10pt",
    textAlign: "justify",
    lineHeight: 1.8,
    marginLeft: "12px",
    width: "67%",
    color: "#595959",
  },

  //3_1유전자검사의 이해
  geneInfo31_Img: {
    width: "210px",
    position: "absolute", 
    top: 100,
    right: 56,
  },
  geneInfo31_Box1: {
    flexDirection: "column",
    display: "flex",    
    marginTop: "352px",
  },
  geneInfo31_Box1_Img1: {
    width: "32px",
    left: 0,
  },
  geneInfo31_Box2: {
    flexDirection: "row",
    display: "flex",
    marginTop: "10px",
  },
  geneInfo31_Box2_Txt1: {
    color: "#222",
    fontSize: "11pt",
    lineHeight: 1.4,
    fontWeight: "normal",
    width: "30%",
  },
  geneInfo31_Box2_Txt2: {
    color: "#595959",
    fontWeight: "normal",
    fontSize: "10pt",
    textAlign: "justify",
    lineHeight: 1.8,
    marginLeft: "12px",
    width: "67%",
  },
  geneInfo31_Box1_Img2: {
    width: "30%",
  },
  //4_결과확인방법
  checkResult4_Box1: {
    position: "relative",
    marginTop: "40px",
    width: "100%",
    height: "auto",
    display: "flex",
    // overflow: "hidden",
    flexDirection: "column",
  },
  checkResult4_Box1_Txt: {
    color: "#222",
    position: "absolute",
    margin: "75pt 0 0",
    width: "100%",
    fontSize: "27.5pt",
    fontWeight: "normal",
    letterSpacing: -1,
  },
  res_back: {
    position: "absolute",
    margin: "92px 0 0",
    width: "380px",
    height: "14px",
    backgroundColor: "#E3E2FF",
    letterSpacing: -0.2,
  },
  checkResult4_Txt1: {
    color: "#222",
    lineHeight: 1.8,
    marginTop: "120px",
    fontweight: "normal",
    fontSize: "11pt",
  },
  checkResult4_Box2: {
    flexDirection: "row",
    display: "flex",
    width: "100%",
    margin: "40px 0 8px",
  },
  checkResult4_Box2_Img: {
    width: "24px",
    height: "24px",
  },
  checkResult4_Box2_Txt: {
    fontSize: "17.5pt",
    paddingLeft: "12px",
    margin: "auto 0",
  },
  checkResult4_Box3: {
    flexDirection: "row",
    margin: "15px 30px 0",
    position: "relative",
    width: "100%",
    height: "60%",
  },
  checkResult4_Box3_Img: {
    position: "absolute",
    width: "30%",
    left: 0,
  },
  checkResult4_Box3_Txt: {
    fontSize: "10pt",
    fontWeight: "normal",
    color: "#222",
    position: "absolute",
    width: "60%",
    right: 20,
    top: 18,
    lineHeight: 1.8,
  },
  checkResult4_Img: {
    position: "absolute",
    width: "58%",
    right: "54pt",
    bottom: "34pt",
  },

  // 4_1결과확인방법
  how_tit_img: {
    width: "24px",
    marginRight: "8px",
  },
  how_box: {
    marginTop: "40px",
    color: "#3428BB",
    fontWeight: 700,
    fontSize: "17.5pt",
  },
  how_tit: {
    fontSize: "10pt",
    color: "#222",
    marginTop: "16px",
  },
  how_inner: {
    margin: "20px auto 0",
    width: "85%",
  },
  how_img: {
    marginTop: "10px",
    width: "100%",
  },
  hb: {
    marginTop: "10px",
    marginBottom: "16px",
  },
  hb_box: {
    marginBottom: "10px",
  },
  how_txt1: {
    fontSize: "10pt",
    color: "#222",
    marginBottom: "6px",
    fontWeight: 700,
  },
  how_txt2: {
    fontSize: "10pt",
    color: "#595959",
    letterSpacing: -0.5,
    lineHeight: 1.4,
  },
  how_table: {
    marginTop: "24px",
    fontSize: "10pt",
    width: "100%",
    overflow: "hidden",
  },
  how_row: {
    width: "100%",
    padding: "6px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottom: "1px solid #595959",
  },
  how_col_1: {
    width: "28%",
    textAlign: "center",
    lineHeight: 1.2,
    marginTop: "4px",
  },
  how_col_1_span: {
    width: "100%",
    fontSize: "8.5pt",
    color: "#929292",
  },
  how_col_2: {
    width: "72%",
    padding: "0 8px",
    color: "#595959",
  },

  //사전안내
  notice_tit: {
    marginTop: "75pt",
    color: "#3428BB",
    letterSpacing: -1,
    fontSize: "28.5pt",
    fontWeight: 800,
  },
  notice_li: {
    width: "16px",
    height: "16px",
    marginRight: "4px",
  },
  no_li_box: {
    textAlign: "justify",
    marginTop: "24px",
    width: "100%",
    overflow: "hidden",
    padding: "24px",
    backgroundColor: "#E9EFF4",
    borderRadius: "16px",
  },
  no_txt: {
    display: "flex",
    flexDirection: "row",
    width: "95%",
    marginBottom: "12px",
    color: "#222",
    fontSize: "10pt",
    lineHeight: 1.6,
  },
  notice_img: {
    position: "absolute",
    bottom: 32,
    right: 56,
    width: "40%",
  },

  //항목별 결과
  dis_tit: {
    marginTop: "12px",
    display: "flex",
    flexDirection: "row",
    marginBottom: "8px",
  },
  dis_tit1: {
    fontSize: "24pt",
    fontWeight: 500,
    color: "#3428BB",
  },
  dis_tit2: {
    margin: "10px 0 0 8px",
    fontSize: "16pt",
    color: "#929292",
  },
  dis_tit_m: {
    margin: "24px 0 12px 0",
  },
  dis_tit_m_2: {
    margin: "8px 0 12px 0",
  },
  disBox: {
    justifyContent: "space-between",
    // backgroundColor: "green",
  },
  dis_left: {
    width: "49%",
    // backgroundColor: "#ccc"
  },
  dis_right: {
    width: "47%",
    // backgroundColor: "#ddd"
  },
  dis_light_box: {
    width: "100%",
    height: "84px",
    justifyContent: "space-between",
    padding: "8px",
    borderRadius: "44px",
    backgroundColor: "#E9EFF4",
  },
  dis_light_img: {
    width: "68px",
    borderRadius: "50px",
  },
  dis_right_txt1: {
    color: "#222",
    fontSize: "14pt",
    lineHeight: 1.4,
  },
  dis_right_txt1_1: {
    color: "#3428BB",
  },
  dis_right_txt1_2: {
    fontWeight: 500,
  },
  dis_right_txt2: {
    marginTop: "8px",
    fontSize: "10.5pt",
    fontWeight: 500,
    lineHeight: 1.4,
  },
  dis_right_txt2_1: {
    color: "#007B5E",
  },
  dis_right_txt2_2: {
    color: "#F99C11",
  },
  dis_right_txt2_3: {
    color: "#F8463B",
  },
  dis_wBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    padding: "16px 28px",
    border: "1px solid #929292",
    borderRadius: "16px",
    marginTop: "24px",
  },
  dis_icns: {
    width: "16px",
  },
  dis_wBox_txt: {
    color: "#222",
    fontSize: "10pt",
    margin: "10px 0 0 4px",
    lineHeight: 1.4,
  },
  dis_prevalence: {
    justifyContent: "space-between",
    color: "#222",
    fontSize: "10pt",
    // backgroundColor:"#eee"
  },
  dis_prevalence_top: {
    fontSize: "11pt",
  },
  dis_pre_h: {
    borderBottom: "1px solid #595959",
  },
  dis_pre_b: {
    fontWeight: 600,
  },
  dis_pre_width: {
    width: "105px",
    // backgroundColor: "yellow"
  },
  dis_right_txt: {
    height: "24px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  dis_prevalence_bottom: {
    fontSize: "11pt",
    color: "#3428BB",
  },
  dis_right_box: {
    padding: "24px 24px 16px 24px",
    // textAlign: "justify",
    textAlign: "left",
  },
  dis_talk_img1: {
    position: "absolute",
    width: "47px",
    top: 0,
    left: 0,
  },
  dis_talk_img2: {
    position: "absolute",
    width: "47px",
    bottom: 0,
    right: 0,
  },
  dis_talk: {
    lineHeight: 1.6,
  },
  purple: {
    color: "#3428BB",
  },
  bold: {
    fontWeight: 500,
  },
  dis_blue_box: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "16px",
    width: "100%",
    overflow: "hidden",
    borderRadius: "8px",
    backgroundColor: "#E9EFF4",
    padding: "8px 40px 18px",
    fontSize: "10pt",
    color: "#222",
    fontWeight: 500,
  },
  dis_blue_box_cnt1: {
    fontSize: "11pt",
    lineHeight: 1.4,
    marginTop: "14px",
  },
  dis_blue_box_cnt2: {
    fontSize: "16pt",
    backgroundColor: "#fff",
    padding: "6px 12px",
    borderRadius: "50px",
    border: "1px dashed #3428BB",
    marginTop: "14px",
  },
  dis_blue_box_cnt3: {
    position: "relative",
  },
  dis_gradation: {
    width: "192px",
  },
  dis_result_boxes: {
    position: "relative",
    left: "20%",
    width: "65%",
    height: "24px",
    padding: "2px 0",
    // backgroundColor: "rgba(0,0,0,0.3)"
  },
  // dis_danger: {
  //   position: "absolute",
  //   transform: "translateX(-50%)",
  //   backgroundColor: "#edf"
  // },
  dis_align_center: {
    textAlign: "center",
  },
  dis_lowHigh: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontWeight: 400,
    marginTop: "4px",
    width: "110%",
    marginLeft: "-8px",
  },
  dis_result_bottomBox: {
    position: "absolute",
    bottom: "-11px",
  },
  dis_avg: {
    position: "absolute",
    bottom: "0px",
    left: "50%",
    transform: "translateX(-50%)",
    // backgroundColor:"rgba(0,0,0,0.3)",
  },
  dis_result_subTxt: {
    color: "#222",
    fontSize: "11pt",
    textAlign: "center",
    margin: "8px 0",
  },
  dis_col_1: {
    padding: "5px",
    width: "23%",
  },
  dis_col_2: {
    padding: "5px",
    width: "18%",
  },
  dis_col_3: {
    padding: "5px",
    width: "15%",
  },
  dis_col_4: {
    padding: "5px",
    width: "18%",
    borderRight: "1px solid #e4e4e4",
  },
  dis_col_5: {
    padding: "5px",
    width: "26%",
  },
  dis_b: {
    borderBottom: "1px solid #E4E4E4",
  },
  red: {
    color: "#F8463B",
  },

  //암 종합정보
  comprehensive_tit: {
    color: "#3428BB",
    fontWeight: 500,
    fontSize: "24pt",
    margin: "16px 0",
  },
  comprehensive_box: {
    width: "100%",
    height: "146px",
    padding: "16px",
    textAlign: "justify",
    borderRadius: "4px",
    backgroundColor: "#E3E2FF",
    color: "#222",
    letterSpacing: -0.7,
    lineHeight: 1.6,
    fontSize: "10pt",
    marginBottom: "20px",
    fontWeight: 300,
  },
  comprehensive_ITB: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
  },
  comprehensive_ITB_height1: {
    height: "110px",
    // backgroundColor: "#eee"
  },
  comprehensive_ITB_height2: {
    height: "176px",
    // backgroundColor: "#ddd",
    position: "relative",
    paddingBottom: "24px",
  },
  comprehensive_ITB_height3: {
    overflow: "hidden",
    // backgroundColor: "#ccc"
  },
  comprehensive_icn1: {
    width: "24px",
    height: "24px",
    marginRight: "10px",
  },
  comp_tit: {
    color: "#222",
    fontWeight: 500,
    fontSize: "11pt",
    marginTop: "-2px",
    marginBottom: "12px",
  },
  comp_back: {
    backgroundColor: "#E3E2FF",
    height: "10px",
    top: "14px",
    left: "0px",
  },
  comp_back1: {
    width: "40px",
  },
  comp_back2: {
    width: "72px",
  },
  comp_back3: {
    width: "84px",
  },
  comp_txt: {
    color: "#595959",
    fontSize: "10pt",
    lineHeight: 1.4,
    paddingRight: "40px",
    textAlign: "justify",
  },
  comp_txt_half: {
    width: "70%",
    // backgroundColor: "yellow",
  },
  comp_cancer_box: {
    position: "absolute",
    bottom: "-24px",
    right: 0,
    width: "148px",
    // backgroundColor: "#eee",
  },
  comp_cancerImg: {
    padding: "4px",
  },

  comp_txt2: {
    height: "90px",
    // backgroundColor: "yellow"
  },
  comp_tit_1: {
    color: "#222",
    fontSize: "10pt",
    fontWeight: 500,
    marginBottom: "6px",
  },
  comprehensive_icn2: {
    width: "16px",
    textAlign: "center",
    margin: "0 8px",
  },
  comprehensive_icn3: {
    width: "16px",
    marginRight: "8px",
  },
  c_icn_tit: {
    margin: "4px 0 0 4px",
    fontWeight: 500,
  },
  c_icn_tit3: {
    color: "#222",
    fontSize: "10pt",
    fontWeight: 500,
  },
  GboxWrap: {
    justifyContent: "space-between",
    marginTop: "8px",
  },
  comp_Gbox: {
    marginTop: "6px",
    position: "relative",
    height: "94px",
    padding: "18px 20px 0",
    borderRadius: "8px",
    border: "1px solid #e4e4e4",
    backgroundColor: "#E9EFF4",
    fontSize: "10pt",
    color: "#595959",
    lineHeight: 1.4,
    letterSpacing: -0.5,
  },
  comp_Gbox1: {
    width: "40%",
  },
  comp_Gbox2: {
    width: "58%",
  },
  comp_Wbox: {
    color: "#222",
    fontWeight: 600,
    fontSize: "10pt",
    width: "90px",
    height: "24px",
    backgroundColor: "#fff",
    borderRadius: "8px",
    border: "1px solid #e4e4e4",
    position: "absolute",
    top: "-11px",
    left: "50%",
    transform: "translateX(-26%)",
    textAlign: "center",
  },
  c_way: {
    marginTop: "12px",
    paddingLeft: "40px",
  },
  comp_txt_m: {
    margin: "6px 0 0 8px",
    paddingRight: "0px",
    textAlign: "justify",
    height: "96px",
  },

  //분석정보
  analysis_tit: {
    fontSize: "24pt",
    color: "#3428BB",
    margin: "16px 0 32px",
    fontWeight: 700,
  },
  analysis_table: {
    marginTop: "12px",
    width: "100%",
    overflow: "hidden",
    textAlign: "center",
    fontSize: "10pt",
    borderTop: "1px soild #929292",
    borderBottom: "1px soild #929292",
  },
  analysis_row: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  an_b: {
    borderBottom: "1px solid #c9c9c9",
  },
  analysis_col_1: {
    width: "20%",
    height: "100%",
    padding: "6px",
    backgroundColor: "#E3E2FF",
    borderRight: "1px soild #c9c9c9",
  },
  analysis_col_2: {
    padding: "6px",
    width: "30%",
    borderRight: "1px soild #c9c9c9",
  },
  analysis_col_3: {
    width: "20%",
    height: "100%",
    padding: "6px",
    backgroundColor: "#E3E2FF",
    borderRight: "1px soild #c9c9c9",
  },
  analysis_col_4: {
    width: "30%",
    padding: "6px",
  },
  a_gh: {
    marginTop: "32px",
  },
  an_gray_table: {
    borderTop: "1px soild #e4e4e4",
    borderBottom: "1px soild #e4e4e4",
    marginTop: "16px",
    width: "100%",
    overflow: "hidden",
    textAlign: "center",
    fontSize: "10pt",
  },
  gray_row: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  gray_colorRow: {
    backgroundColor: "#F8F8F8",
    borderBottom: "1px soild #e4e4e4",
  },
  gray_col_1: {
    width: "25%",
    padding: "6px",
    borderRight: "1px solid #E4E4E4",
  },
  gray_col_2: {
    width: "25%",
    padding: "6px",
    borderRight: "1px solid #E4E4E4",
  },
  gray_col_3: {
    width: "25%",
    padding: "6px",
    borderRight: "1px solid #E4E4E4",
  },
  gray_col_4: {
    width: "25%",
    padding: "6px",
  },
  an_txt: {
    marginTop: "8px",
    color: "#929292",
    fontSize: "8pt",
    letterSpacing: -0.4,
  },
  an_box: {
    margin: "24px 0 32px",
    borderTop: "1px solid #929292",
    padding: "16px 0 8px",
  },
  an_box_img: {
    width: "32%",
    marginLeft: "56px",
    padding: "4px",
  },
  an_box_txt: {
    width: "55%",
    fontSize: "9pt",
    color: "#222",
    fontWeight: 700,
  },
  an_title: {
    padding: "4px 8px",
    backgroundColor: "#E4E4E4",
    borderRadius: "4px",
    width: "80px",
    textAlign: "center",
    marginBottom: "8px",
  },
  an_mar: {
    marginBottom: "8px",
  },
  an_title_txt: {
    width: "40px",
    color: "#595959",
  },
  an_title_txt2: {
    fontWeight: 400,
  },
  sign_img: {
    height: "100%",
    margin: "0 auto",
  },
  s_1: {
    width: "88px",
  },
  s_2: {
    width: "80px",
  },
  s_3: {
    width: "108px",
  },
  purple_table: {
    borderTop: "1px soild #929292",
    borderBottom: "1px soild #929292",
  },
  purple_colorRow: {
    backgroundColor: "#E3E2FF",
    borderBottom: "1px soild #E4E4E4",
  },
  purple_row_1: {
    height: "60px",
  },
  purple_col_1: {
    height: "60px",
  },
  purple_b: {
    borderTop: "1px solid #E4E4E4",
  },

  //참고문헌
  references_Txt: {
    marginTop: "32px",
    width: "100%",
    fontSize: "28.5pt",
  },
  references_Box1: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    height: "auto",
    overflow: "hidden",
    backgroundColor: "#E9EFF4",
    borderRadius: "16px",
    marginTop: "16px",
    padding: "24px",
    position: "relative",
  },
  references_Box2_Txt1: {
    width: "47%",
    fontSize: "8.5pt",
    fontWeight: "normal",
    lineHeight: 1.4,
    color: "#595959",
    letterSpacing: -0.2,
  },
  references_Box3: {
    display: "flex",
    flexDirection: "row",
    marginTop: "12px",
    width: "100%",
    height: "auto",
    overflow: "hidden",
    backgroundColor: "#E9EFF4",
    borderRadius: "16px",
    position: "relative",
    alignItems: "center",
    padding: "12px",
    lineHeight: 1.6,
  },
  references_Box4_Img: {
    width: "8%",
    marginLeft: "10px",
    marginRight: "10px",
  },
  references_Box3_Txt: {
    width: "85%",
    fontSize: "9pt",
    fontWeight: "normal",
    lineHeight: 1.3,
    color: "#595959",
    paddingLeft: "10px",
    borderLeft: "1px solid #3428BB",
  },
  references_Box3_Txt_in: {
    marginBottom: "4px",
  },

  //광고자료 페이지
  addWrap: {
    width: "100%",
    height: "100%",
    position: "relative",
    fontFamily: "NanumSquare",
  },
  add_box: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  addPage: {
    width: "100%",
  },

  //이미지 페이지
  imgpage_box: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  IMGpage: {
    width: "100%",
  },

  //마지막 페이지
  lastPage_Box1: {
    display: "flex",
    flexDirection: "row",
    margin: "0 auto",
    width: "100%",
    height: "100%",
    position: "relative",
  },
  lastPage_Box2: {
    display: "flex",
    flexDirection: "column",
    width: "60%",
    height: "auto",
    overflow: "hidden",
    position: "absolute",
    bottom: 55,
  },
  lastPage_Box2_Img: {
    width: "180px",
    marginBottom: "32px",
  },
  lastPage_Box2_Txt: {
    fontWeight: "normal",
    color: "#222",
    lineHeight: 1.8,
    fontSize: "11pt",
  },
  lastPage_Box2_Txt_img: {
    width: "264px",
  },
  lastPage_Box3: {
    position: "absolute",
    right: 0,
    top: 80,
  },
  lastPage_Box3_Img: {
    width: "130px",
    marginBottom: "10px",
  },


  //강남지인병원용
  pg2Img_gangnam: {
    width: "30%",
    position: "absolute",
    top: "32px",
    right: "56px",
  },

  pg2Img_1 : {  
    width: "70px",
    position: "absolute",
    top: "32px",
    left: "32px",
    padding: "2px",
  },

  product_name_gangnam: {
    marginTop: "64px",
    color: "#595959",
    fontWeight: 500,
    fontSize: "16pt",
    letterSpacing: -1,
  },

  tit_back1_gangnam: {
    marginTop: "20px",
    marginBottom: "-32px",
    width: "230px",
    height: "14px",
    backgroundColor: "#E3E2FF",
    letterSpacing: "0.02pt",
  },

  product_tit_gangnam: {
    marginTop: "8px",
    fontWeight: 700,
    color: "#3428BB",
    fontSize: "20pt",
    letterSpacing: -1,
  },
  info_row : {
    display: "flex",
    flexDirection: "row",
    borderBottom: "1px solid #C9C9C9",
  },
  info_row_none: {
    borderBottom: "none",
  },
  info_col: {
    padding: "6px",
    textAlign: "center",

  },
  info_col_1 : {
    width: "16%",
    backgroundColor: "#E9EFF4",
  },
  info_col_2 : {
    width: "34%",
  },

  // 푸터
  bottomImg: {
    position: "absolute",
    width: "56px",
    left: "48pt",
    bottom: "24pt",
    padding: "4px"
  },

  re_bottom: {
    width: "100%",
    textAlign: "right",
    padding: "10px 0",
    borderTop: "1px solid #929292",
    fontSize: "9pt",
    color: "#595959",
    position: "absolute",
    left: "48pt",
    bottom: "24pt",
    letterSpacing: -0.6,
  },

  re_in: {
    marginLeft: "16px",
  },



  // 전체결과요약_일송학원강남성심병원
  pg2Img_ilsong: {
    width: "30%",
    position: "absolute",
    top: "32px",
    right: "56px",
  },

  pg2Img_ilsong_1 : {  
    width: "70px",
    position: "absolute",
    top: "32px",
    left: "47pt",
    padding: "2px",
  },


  ilsong_wrap: {
    width: "100%",
    height: "100%",
    position: "relative",
    padding: "24pt 48pt",
    fontFamily: "NanumSquare",
  },

  day_box_table: {
      display: "flex",
      flexDirection:"column",
      marginTop: "16px",
      width: "100%",
      fontSize: "10pt",
      borderTop: "1px solid #929292",
      borderBottom: "1px solid #929292",
  },
  day_row: {
      display: "flex",
      flexDirection:"row",
  },
  day_col_1 : {
    padding: "6px",
    textAlign: "center",
    width: "14%",
    backgroundColor: "#E9EFF4",
    color:"#595959",
    fontWeight:"medium",
    borderRight: "1px slid #C9C9C9"
  },

  day_col_2 : {
    padding: "6px 9px",
    width: "19%",
  },

  ilsong_box_table: {
    width: "100%",
    fontSize: "10pt",
    borderTop: "1px solid #929292",
    borderBottom: "1px solid #929292",
    marginTop:"6px",
  },

  ilsong_row_none :{
    display: "flex",
    flexDirection: "row",
  },

  ilsong_col_wrapper : {
    display:"flex",
    flexDirection:"row",
    height:"36px",
    backgroundColor: "#E9EFF4",
    color:"#595959",
    fontWeight:"medium",
  },
  
  ilsong_col :{
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    justifyContent:"center",
    width:"100%",
    borderRight: "1px solid #C9C9C9",
    borderBottom: "1px solid #C9C9C9",
    fontSize: "10pt",
  },

  ilsong_col_1 : {
    width: "14%",
    backgroundColor: "#E9EFF4",
    color:"#595959",
    fontWeight:"medium",
    borderRight:"1px solid #C9C9C9",
  },

  add_left_border : {
    borderLeft:"1px solid #C9C9C9",
  },
  
  ilsong_col_2 : {
    width: "36%",
    paddingLeft:"9px",
    textAlign:"left",
  },

  ilsong_bottom_col: {
    display:"flex",
    flexDirection:"row",
    border:"none",
    height:"50%",
  },

  ilsong_no_col : {
    width: "6%",
  },
  ilsong_deases_col :{
    width:"26%"
  },
  
  ilsong_danger_col :{
    width:"28%",
  },

  ilsong_half_center : {
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    height:"50%",
  },

  ilsong_genetic_col: {
    borderBottom: "1px solid #C9C9C9",
    width:"100%",
    borderLeft: "none",
  },

  danger_result:{
    height:"100%",
    width:"44%",
    borderRight: "1px solid #C9C9C9",
  },

  danger_step:{
    height:"100%",
    width:"56%",
  },
  
  ilsong_prevalence_col :{
    width:"40%",
    borderLeft:"none",
    borderRight:"none"
  },

  prevalence_average: {
    height:"100%",
    width:"32%",
    borderRight: "1px solid #C9C9C9",
  },

  prevalence_expectations: {
    height:"100%",
    width:"68%",
  },

  ilsong_row_wrapper: {
    display:"flex",
    flexDirection:"row",
    height:"24px",
    borderBottom:"1px solid #C9C9C9",
  },

  ilsong_row_wrapper_none: {
    display:"flex",
    flexDirection:"row",
    height:"24px",
  },

  last_row: {
    borderBottom:"none",
  },

  ilsong_row_info: {
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    borderRight:"1px solid #C9C9C9",
  },

  ilsong_no_row:{
    width:"6%",
  },

  ilsong_deases_row:{
    width:"26%",
  },

  ilsong_result_row:{
    width:"12.2%",
  },
  
  ilsong_step_row:{
    width:"15.8%",
  },
  
  ilsong_average_row:{
    width:"12.7%",
  },
  
  ilsong_expectations_row:{
    width:"25.3%",
    borderRight:"none",
  },

  ilsong_description_wrapper: {
    width:"100%",
    bottom:"70px",
    height: "70px",
    position: "absolute",
    backgroundColor:"#E9EFF4",
    left: "20%",
    transform: "translateX(-50%)",
    borderRadius:"12px",
    display:"flex",
    flexDirection:"column",
    justifyContent:"space-between",
    padding:"8px 18px",
    letterSpacing: "-0.135px",
    fontSize:"9pt",
  },

  ilsong_description_block: {
    width:"100%",
    display:"flex",
    flexDirection:"column",
  },

  ilsong_description_title:{
    fontWeight:"bold",
    color:"#333333",
    marginBottom:"3px"
  },
  ilsong_description_contents:{
    color:"#595959",
    // marginBottom:"10px"
  },

  grp_h:{
    fontWeight:"bold",
    color:"#FF5757",
  },
  
  grp_a:{
    fontWeight:"bold",
    color:"#FFB03A"
  },
  
  grp_l:{
    fontWeight:"bold",
    color:"#14AB87",
  },
});

const Headlogo = ({ children, ...props }: any) => (
  <Image src="/img/report/report_hancomGX_logo_righttop.png" style={styles.headlogo} {...props}>
    {children}
  </Image>
);
const BottomTxt = ({ children, ...props }: any) => (
  <Text style={styles.bottomTxt} {...props}>
    {children}
  </Text>
);

const Br: any = () => "\n";

const reviewDate = (rday: any) => {
  const reday = rday;
  if (reday) {
    const redayArr = reday.split(" ");
    return redayArr[0];
  }
};

const DiseaseInfo = ({ chkData2 }: any) => {
  // console.log(repResult[0]);
  // const arr1: Report_results[] = repResult[0];
  // console.log("===디지즈인포=====" + chkData2);

  //------------------------------------------
  const grpToString1 = (grp: any) => {
    if (grp === 1) {
      return "보통의 관리가";
    } else if (grp === 2) {
      return "관심이";
    } else {
      return "주의가";
    }
  };

  const grpToString2 = (grp: any) => {
    if (grp === 1) {
      return "관심이";
    } else if (grp === 2) {
      return "주의가";
    } else {
      return "각별한 관리가";
    }
  };

  const genderToString = (gender: any) => {
    if (gender === "F") {
      return "여성";
    } else {
      return "남성";
    }
  };

  const korAvg = (kor: any) => {
    if (kor) {
      let kornum = kor.replace("%", "");
      kornum = Number(kornum);
      if (kornum > 1) {
        return kornum.toFixed(2);
      } else if (kornum <= 1) {
        return kornum.toFixed(3);
      }
    }
  };
  const korAvgP = (kor: any) => {
    if (kor) {
      let kornum = kor.replace("%", "");
      kornum = Number(kornum);
      if (kornum > 1) {
        let kornumc = Number(kornum.toFixed(2));
        if (kornumc < 0.1) {
          return "10만명";
        } else if (1 > kornumc && kornumc >= 0.1) {
          return "1만명";
        } else if (10 > kornumc && kornumc >= 1) {
          return "1000명";
        } else if (kornumc >= 10) {
          return "100명";
        }
      } else if (kornum <= 1) {
        let kornumc = Number(kornum.toFixed(3));
        if (kornumc < 0.1) {
          return "10만명";
        } else if (1 > kornumc && kornumc >= 0.1) {
          return "1만명";
        } else if (10 > kornumc && kornumc >= 1) {
          return "1000명";
        } else if (kornumc >= 10) {
          return "100명";
        }
      }
    }
  };

  const korAvgC = (kor: any) => {
    if (kor) {
      let kornum = kor.replace("%", "");
      kornum = Number(kornum);
      if (kornum > 1) {
        let kornumc = kornum.toFixed(2);
        if (kornumc < 0.1) {
          let kornumd = 100000 * kornumc * 0.01;
          return kornumd.toFixed(2);
        } else if (1 > kornumc && kornumc >= 0.1) {
          let kornumd = 10000 * kornumc * 0.01;
          return kornumd.toFixed(2);
        } else if (10 > kornumc && kornumc >= 1) {
          let kornumd = 1000 * kornumc * 0.01;
          return kornumd.toFixed(2);
        } else if (kornumc >= 10) {
          let kornumd = 100 * kornumc * 0.01;
          return kornumd.toFixed(2);
        }
      } else if (kornum <= 1) {
        let kornumc = kornum.toFixed(3);
        if (kornumc < 0.1) {
          let kornumd = 100000 * kornumc * 0.01;
          return kornumd.toFixed(2);
        } else if (1 > kornumc && kornumc >= 0.1) {
          let kornumd = 10000 * kornumc * 0.01;
          return kornumd.toFixed(2);
        } else if (10 > kornumc && kornumc >= 1) {
          let kornumd = 1000 * kornumc * 0.01;
          return kornumd.toFixed(2);
        } else if (kornumc >= 10) {
          let kornumd = 100 * kornumc * 0.01;
          return kornumd.toFixed(2);
        }
      }
    }
  };

  const myAvg = (koavg: any, prevalence: any) => {
    if (koavg) {
      let kornum = koavg.replace("%", "");
      kornum = Number(kornum);
      let preval = Number(prevalence);
      if (kornum > 1) {
        let kornumc = kornum.toFixed(2);
        let myAverage = kornumc * preval;
        if (myAverage > 1) {
          return myAverage.toFixed(2);
        } else if (myAverage <= 1) {
          return myAverage.toFixed(3);
        }
      } else if (kornum <= 1) {
        let kornumc = kornum.toFixed(3);
        //console.log(kornumc);
        let myAverage = kornumc * preval;
        //console.log(myAverage);
        if (myAverage > 1) {
          let mya = myAverage.toFixed(2);
          mya = mya.toString();
          //console.log(myAverage.toFixed(2));
          return mya;
        } else if (myAverage <= 1) {
          let mya = myAverage.toFixed(3);
          mya = mya.toString();
          //console.log(myAverage.toFixed(3));
          return mya;
        }
      }
    }
  };

  const myAvgC = (koavg: any, prevalence: any) => {
    if (koavg) {
      let kornum = koavg.replace("%", "");
      kornum = Number(kornum);
      let preval = Number(prevalence);
      if (kornum > 1) {
        let kornumc = kornum.toFixed(2);
        let myAverage = kornumc * preval;
        if (myAverage > 1) {
          let mya = Number(myAverage.toFixed(2));
          if (kornumc < 0.1) {
            return (mya * 100000 * 0.01).toFixed(2);
          } else if (1 > kornumc && kornumc >= 0.1) {
            return (mya * 10000 * 0.01).toFixed(2);
          } else if (10 > kornumc && kornumc >= 1) {
            return (mya * 1000 * 0.01).toFixed(2);
          } else if (kornumc >= 10) {
            return (mya * 100 * 0.01).toFixed(2);
          }
        } else if (myAverage <= 1) {
          let mya = Number(myAverage.toFixed(3));
          if (kornumc < 0.1) {
            return (mya * 100000 * 0.01).toFixed(2);
          } else if (1 > kornumc && kornumc >= 0.1) {
            return (mya * 10000 * 0.01).toFixed(2);
          } else if (10 > kornumc && kornumc >= 1) {
            return (mya * 1000 * 0.01).toFixed(2);
          } else if (kornumc >= 10) {
            return (mya * 100 * 0.01).toFixed(2);
          }
        }
      } else if (kornum <= 1) {
        let kornumc = kornum.toFixed(3);
        let myAverage = kornumc * preval;
        if (myAverage > 1) {
          let mya = Number(myAverage.toFixed(2));
          if (kornumc < 0.1) {
            return (mya * 100000 * 0.01).toFixed(2);
          } else if (1 > kornumc && kornumc >= 0.1) {
            return (mya * 10000 * 0.01).toFixed(2);
          } else if (10 > kornumc && kornumc >= 1) {
            return (mya * 1000 * 0.01).toFixed(2);
          } else if (kornumc >= 10) {
            return (mya * 100 * 0.01).toFixed(2);
          }
        } else if (myAverage <= 1) {
          let mya = Number(myAverage.toFixed(3));
          if (kornumc < 0.1) {
            return (mya * 100000 * 0.01).toFixed(2);
          } else if (1 > kornumc && kornumc >= 0.1) {
            return (mya * 10000 * 0.01).toFixed(2);
          } else if (10 > kornumc && kornumc >= 1) {
            return (mya * 1000 * 0.01).toFixed(2);
          } else if (kornumc >= 10) {
            return (mya * 100 * 0.01).toFixed(2);
          }
        }
      }
    }
  };

  const statusText = (grp: any) => {
    if (grp === 1) {
      return (
        <Text style={[styles.dis_right_txt2, styles.dis_right_txt2_1]}>
          유전적 요인 외에도 질병 유무, 식습관, 운동습관 등 <Br />
          환경적 요인들도 관여하므로 {grpToString2(grp)} 필요합니다.
        </Text>
      );
    } else if (grp === 2) {
      return (
        <Text style={[styles.dis_right_txt2, styles.dis_right_txt2_2]}>
          유전적 요인 외에도 질병 유무, 식습관, 운동습관 등 <Br />
          환경적 요인들도 관여하므로 {grpToString2(grp)} 필요합니다.
        </Text>
      );
    } else if (grp === 3) {
      return (
        <Text style={[styles.dis_right_txt2, styles.dis_right_txt2_3]}>
          유전적 요인 외에도 질병 유무, 식습관, 운동습관 등 <Br />
          환경적 요인들도 관여하므로 {grpToString2(grp)} 필요합니다.
        </Text>
      );
    }
  };

  const statusCircle = (grp: any) => {
    if (grp === 1) {
      return (
        <>
          <Image src="/img/report/report_result_status_default_1.png" style={styles.dis_light_img}></Image>
          <Image src="/img/report/report_result_status_default_2.png" style={styles.dis_light_img}></Image>
          <Image src="/img/report/report_result_status_activated_3.png" style={styles.dis_light_img}></Image>
        </>
      );
    } else if (grp === 2) {
      return (
        <>
          <Image src="/img/report/report_result_status_default_1.png" style={styles.dis_light_img}></Image>
          <Image src="/img/report/report_result_status_activated_2.png" style={styles.dis_light_img}></Image>
          <Image src="/img/report/report_result_status_default_3.png" style={styles.dis_light_img}></Image>
        </>
      );
    } else if (grp === 3) {
      return (
        <>
          <Image src="/img/report/report_result_status_activated_1.png" style={styles.dis_light_img}></Image>
          <Image src="/img/report/report_result_status_default_2.png" style={styles.dis_light_img}></Image>
          <Image src="/img/report/report_result_status_default_3.png" style={styles.dis_light_img}></Image>
        </>
      );
    }
  };

  const myType = (genotype: any, riskallele: any) => {
    let str = genotype;
    let a = str.substring(0, 1);
    let b = str.substring(1);
    let risk = riskallele;
    // console.log(str + "//" + a + "//" + b + "//" + risk + "//");
    if (a === risk && b === risk) {
      return (
        <Text>
          <Text style={styles.red}>
            {a}
            {b}
          </Text>
        </Text>
      );
    } else if (a === risk && b !== risk) {
      return (
        <Text>
          <Text style={styles.red}>{a}</Text>
          {b}
        </Text>
      );
    } else if (a !== risk && b === risk) {
      return (
        <Text>
          {a}
          <Text style={styles.red}>{b}</Text>
        </Text>
      );
    } else if (a !== risk && b !== risk) {
      return (
        <Text>
          <Text>
            {a}
            {b}
          </Text>
        </Text>
      );
    }
  };

  const imageSrc = (disId: any) => {
    const a = "/img/report/disease/";
    const b = ".png";
    let c = "";
    if (disId) {
      c = disId;
      //console.log(a + c + b);
      return a + c + b;
    } else {
      return a + "n" + b;
    }
  };

  const chkKorean = (name: any) => {
    const lastChar = name.charCodeAt(name.length - 1);
    const isThereLastChar = (lastChar - 0xac00) % 28;
    if (isThereLastChar) {
      return "이 ";
    }
    return "가 ";
  };

  const newArray = chkData2.filter((item: { disease: any }, i: any) => {
    return (
      chkData2.findIndex((item2: { disease: any }, j: any) => {
        return item.disease === item2.disease;
      }) === i
    );
  });

  //repResult 를 arr1로 바꾸기
  const DiseaseTable = (disease: any) => {
    const findIndexArray = chkData2
      .map((item: any, index: any) => {
        if (item.disease === disease) return index;
        else return -1;
      })
      .filter((item: any) => item !== -1);

    let first = findIndexArray[0];
    let last = findIndexArray[findIndexArray.length - 1];

    const rows: any = [];
    for (let i = first; i <= last; i++) {
      rows.push(
        <View style={[styles.gray_row, styles.dis_b]} key={chkData2[i].array_id}>
          <View style={[styles.gray_col_1, styles.dis_col_1]}>
            <Text>{chkData2[i].gene}</Text>
          </View>
          <View style={[styles.gray_col_2, styles.dis_col_2]}>
            <Text>{chkData2[i].allele}</Text>
          </View>
          <View style={[styles.gray_col_3, styles.dis_col_3]}>
            <Text style={styles.red}>{chkData2[i].risk_allele}</Text>
          </View>
          <View style={[styles.gray_col_4, styles.dis_col_4]}>{myType(chkData2[i].genotype, chkData2[i].risk_allele)}</View>
          <View style={[styles.dis_col_5]}>
            <Text>{chkData2[i].risk_maker}</Text>
          </View>
        </View>
      );
      // ));
      //  }
    }
    return <>{rows}</>;
  };

  return (
    <>
      {newArray.map((it: any) => (
        <React.Fragment key={it.array_id}>
          <Page size="A4" style={styles.wrap}>
            <Headlogo />

            <View style={styles.dis_tit}>
              <Text style={styles.dis_tit1}>{it.disease}</Text>
              <Text style={styles.dis_tit2}>{it.disease_eng}</Text>
            </View>

            <View style={[styles.imgTxtBox, styles.dis_tit_m, styles.dis_tit_m_2]}>
              <Image src="/img/report/report_result_health.png" style={styles.gh_img} />
              <Text style={[styles.gh_tit]}>종합 결과</Text>
            </View>

            <View style={[styles.imgTxtBox, styles.disBox]}>
              <View style={styles.dis_left}>
                <View style={[styles.imgTxtBox, styles.dis_light_box]}>{statusCircle(it.grp)}</View>
              </View>
              <View style={styles.dis_right}>
                <Text style={styles.dis_right_txt1}>
                  유전적으로 <Text style={[styles.dis_right_txt1_1, styles.bold]}>{it.disease}</Text>에 대해 <Br />
                  <Text style={styles.dis_right_txt1_2}>{grpToString1(it.grp)}</Text> 필요한 것으로 나타납니다.
                </Text>
                {statusText(it.grp)}
              </View>
            </View>

            <View style={[styles.dis_wBox]}>
              <View style={styles.dis_left}>
                <View style={styles.imgTxtBox}>
                  <Image src="/img/report/report_result_checkUp.png" style={styles.gh_img} />
                  <Text style={[styles.gh_tit]}>확인하세요!</Text>
                </View>
                <Text style={styles.dis_wBox_txt}>{it.checkup}</Text>
              </View>
              <View style={styles.dis_right}>
                <View style={styles.imgTxtBox}>
                  <Image src="/img/report/report_result_caution.png" style={styles.gh_img} />
                  <Text style={[styles.gh_tit]}>주의하세요!</Text>
                </View>
                <Text style={styles.dis_wBox_txt}>{it.caution}</Text>
              </View>
            </View>

            <View style={[styles.imgTxtBox, styles.dis_tit_m]}>
              <Image src="/img/report/report_result_health.png" style={styles.gh_img} />
              <Text style={[styles.gh_tit]}>유전적 위험도를 반영한 고객님의 예상 유병률</Text>
            </View>

            <View style={[styles.dis_prevalence, styles.imgTxtBox]}>
              <View style={styles.dis_left}>
                <View style={[styles.dis_right_txt, styles.dis_pre_h]}>
                  <Text style={[styles.dis_pre_width]}>한국인 {genderToString(it.gender)} 평균 유병률</Text>
                  <Text style={styles.dis_prevalence_top}>
                    {korAvg(it.kor_avg)}% ({korAvgC(it.kor_avg)}명/ {korAvgP(it.kor_avg)})
                  </Text>
                </View>
                <View style={[styles.dis_right_txt, styles.dis_pre_b]}>
                  <Text style={[styles.dis_pre_width]}>고객님의 예상 유병률</Text>
                  <Text style={styles.dis_prevalence_bottom}>
                    {myAvg(it.kor_avg, it.prevalence)}% ({myAvgC(it.kor_avg, it.prevalence)}명/{korAvgP(it.kor_avg)})
                  </Text>
                </View>
              </View>
              <View style={[styles.dis_right, styles.dis_right_box]}>
                <Image src="/img/report/report_result___1.png" style={styles.dis_talk_img1}></Image>
                <Text style={styles.dis_talk}>
                  일반적으로 한국인 {korAvgP(it.kor_avg)} 당 {korAvgC(it.kor_avg)}명에서 <Text style={styles.bold}>{it.disease}</Text>
                  {/* 이/가 */}
                  {chkKorean(it.disease)}
                  발생하는 것과 비교하여, 고객님과 같은 유전형은{" "}
                  <Text style={[styles.purple, styles.bold]}>
                    {korAvgP(it.kor_avg)} 당 {myAvgC(it.kor_avg, it.prevalence)}명
                  </Text>
                  에서 <Text style={[styles.purple, styles.bold]}>{it.disease}</Text>
                  {/* 이/가 */}
                  {chkKorean(it.disease)}
                  발생할 수 있습니다.
                </Text>
                <Image src="/img/report/report_result___2.png" style={styles.dis_talk_img2}></Image>
              </View>
            </View>

            <View style={styles.dis_blue_box}>
              <Text style={[styles.dis_blue_box_cnt1, styles.dis_align_center]}>
                유전적 요인에 따른
                <Br />
                상대적 위험도
              </Text>
              <Text style={[styles.dis_blue_box_cnt2, styles.purple, styles.bold]}>{it.prevalence}배</Text>
              <View style={styles.dis_blue_box_cnt3}>
                <View style={styles.dis_result_boxes}>
                  <Text style={{ color: "#3428BB", fontWeight: 500, textAlign: "center", position: "absolute", transform: "translateX(-50%)", left: `${it.percentage}%` }}>
                    나의 위험도: {it.prevalence}배<Br />▼
                  </Text>
                </View>
                <Image src="/img/report/gradation.png" style={styles.dis_gradation}></Image>
                <View style={[styles.bold, styles.dis_lowHigh]}>
                  <Text>낮음</Text>
                  <Text>높음</Text>
                </View>
                <View style={[styles.dis_result_boxes, styles.dis_result_bottomBox]}>
                  <Text style={[styles.dis_align_center, styles.dis_avg]}>
                    ▲<Br />
                    한국인 평균 위험도 1.0
                  </Text>
                </View>
              </View>
            </View>

            <Text style={styles.dis_result_subTxt}>
              한국인의 <Text style={styles.bold}>{it.disease}</Text> 발생의 <Text style={styles.bold}>평균적인 위험도 1.0</Text>과 비교하여 고객님의{" "}
              <Text style={styles.bold}>상대적 위험도는 {it.prevalence}</Text> 입니다.
            </Text>

            <View style={[styles.an_gray_table, styles.purple_table]}>
              <View style={[styles.gray_row, styles.purple_colorRow]}>
                <View style={[styles.gray_col_1, styles.dis_col_1]}>
                  <Text>유전자명</Text>
                </View>
                <View style={[styles.gray_col_2, styles.dis_col_2]}>
                  <Text>대립인자</Text>
                </View>
                <View style={[styles.gray_col_3, styles.dis_col_3]}>
                  <Text>영향인자</Text>
                </View>
                <View style={[styles.gray_col_4, styles.dis_col_4]}>
                  <Text>나의 유전형</Text>
                </View>
                <View style={[styles.dis_col_5]}>
                  <Text>영향인자별 상대적 위험도</Text>
                </View>
              </View>
              {DiseaseTable(it.disease)}
            </View>

            <BottomTxt>본 검사결과가 갖는 임상적 의미가 확립되지 않았으며, 이에 따르는 건강에 관련한 행위가 유용하다는 객관적 타당성이 아직 부족합니다.</BottomTxt>
          </Page>
          <Page size="A4" style={styles.wrap}>
            <Headlogo></Headlogo>
            <Text style={styles.comprehensive_tit}>{it.disease} 종합정보</Text>

            <View style={styles.comprehensive_box}>
              <Text>{it.definition}</Text>
            </View>

            <View style={[styles.comprehensive_ITB, styles.comprehensive_ITB_height1]}>
              <Image src="/img/report/report_cancerInfo_1.png" style={styles.comprehensive_icn1}></Image>
              <View>
                <View style={[styles.comp_back, styles.comp_back1]}></View>
                <Text style={styles.comp_tit}>위험요인</Text>
                <Text style={[styles.comp_txt]}>{it.risk_factor}</Text>
              </View>
            </View>
            <View style={[styles.comprehensive_ITB, styles.comprehensive_ITB_height2]}>
              <Image src="/img/report/report_cancerInfo_2.png" style={styles.comprehensive_icn1}></Image>
              <View>
                <View style={[styles.comp_back, styles.comp_back2]}></View>
                <Text style={styles.comp_tit}>관련검사와 진단</Text>
                <Text style={styles.comp_tit_1}>진단</Text>
                <Text style={[styles.comp_txt, styles.comp_txt2]}>{it.diagnosis}</Text>
                <Text style={styles.comp_tit_1}>관련검사</Text>
                <Text style={[styles.comp_txt, styles.comp_txt_half]}>{it.high_risk_test}</Text>
                {/* 종합정보 그래프이미지 */}
                <View style={styles.comp_cancer_box}>
                  <Image style={styles.comp_cancerImg} src={imageSrc(it.genetic_factor)}></Image>
                  {/* <Chart/> */}
                </View>
              </View>
            </View>
            <View style={[styles.comprehensive_ITB, styles.comprehensive_ITB_height3]}>
              <Image src="/img/report/report_cancerInfo_3.png" style={styles.comprehensive_icn1}></Image>
              <View>
                <View style={[styles.comp_back, styles.comp_back3]}></View>
                <Text style={styles.comp_tit}>이렇게 예방하세요!</Text>

                <View style={[styles.imgTxtBox, styles.GboxWrap]}>
                  <View style={[styles.comp_Gbox, styles.comp_Gbox1]}>
                    <View style={[styles.imgTxtBox, styles.comp_Wbox]}>
                      <Image src="/img/report/report_cancerInfo_4.png" style={styles.comprehensive_icn2}></Image>
                      <Text style={styles.c_icn_tit}>식생활</Text>
                    </View>
                    <Text>{it.prevent_food}</Text>
                  </View>
                  <View style={[styles.comp_Gbox, styles.comp_Gbox2]}>
                    <View style={[styles.imgTxtBox, styles.comp_Wbox]}>
                      <Image src="/img/report/report_cancerInfo_5.png" style={styles.comprehensive_icn2}></Image>
                      <Text style={styles.c_icn_tit}>일상생활</Text>
                    </View>
                    <Text>{it.prevent_daily}</Text>
                  </View>
                </View>
              </View>
            </View>

            <View style={styles.c_way}>
              <View style={styles.imgTxtBox}>
                <Image src="/img/report/report_cancerInfo_6.png" style={styles.comprehensive_icn3}></Image>
                <Text style={styles.c_icn_tit3}>관리방법</Text>
              </View>
              <Text style={[styles.comp_txt, styles.comp_txt_m]}>{it.mng_method}</Text>
            </View>
            <BottomTxt>본 검사결과가 갖는 임상적 의미가 확립되지 않았으며, 이에 따르는 건강에 관련한 행위가 유용하다는 객관적 타당성이 아직 부족합니다.</BottomTxt>
          </Page>
        </React.Fragment>
      ))}
    </>
  );
};

//const token = sessionStorage.getItem("auth");
interface pdfType {
  chkData: ResultData[];
  chkData2: any;
  page0? : boolean;
}

interface ReportResultsWithIndex extends Report_results {
  index: number;
}

const ReportDocument = ({ chkData, chkData2, page0 }: pdfType) => {
  // 한국인 평균
  const korAvg = (kor: any) => {
    if (kor) {
      let kornum = kor.replace("%", "");
      kornum = Number(kornum);
      if (kornum > 1) {
        return kornum.toFixed(2);
      } else if (kornum <= 1) {
        return kornum.toFixed(3);
      }
    }
  };
  
  // 고객님 예상 평균
  const myAvg = (koavg: any, prevalence: any) => {
    if (koavg) {
      let kornum = koavg.replace("%", "");
      kornum = Number(kornum);
      let preval = Number(prevalence);
      if (kornum > 1) {
        let kornumc = kornum.toFixed(2);
        let myAverage = kornumc * preval;
        if (myAverage > 1) {
          return myAverage.toFixed(2);
        } else if (myAverage <= 1) {
          return myAverage.toFixed(3);
        }
      } else if (kornum <= 1) {
        let kornumc = kornum.toFixed(3);
        //console.log(kornumc);
        let myAverage = kornumc * preval;
        //console.log(myAverage);
        if (myAverage > 1) {
          let mya = myAverage.toFixed(2);
          mya = mya.toString();
          //console.log(myAverage.toFixed(2));
          return mya;
        } else if (myAverage <= 1) {
          let mya = myAverage.toFixed(3);
          mya = mya.toString();
          //console.log(myAverage.toFixed(3));
          return mya;
        }
      }
    }
  };

  return (
    <>
      {chkData.map((it) => {
        // 중복 값 제거
        const analysisArray = (it.reposrt_results ?? []).filter((item: { disease: any }, idx: number, arr: { disease: any }[]) => {
          return arr.findIndex((item2) => item.disease === item2.disease) === idx;
        })

        // 내림차순 정렬
        const sortArray = analysisArray.sort((a, b) => {
          const grpA = a.grp ?? 0;
          const grpB = b.grp ?? 0;
          return grpB - grpA; // 내림차순 정렬
        });

        // 인덱스 추가
        const indexedArray = sortArray.map((item:any, idx:number) => ({
          ...item,
          index: idx + 1, // 1부터 시작하는 index 부여
        }));

        const sliceArray: ReportResultsWithIndex[][] = [];

        // 13개씩 나누기
        for (let i = 0; i < indexedArray.length; i += 13) {
          const newObj = indexedArray.slice(i, i + 13)
          sliceArray.push(newObj);
        }

        return (
        <Document key={it.idx}>
          {/* 0_표지_앞면 */}
          <Page size="A4" style={styles.wrap}>
            <Image src="/img/report/logo.png" style={styles.coverHead_logo}></Image>
            <Image src="/img/report/version2.png" style={styles.coverHead_version2}></Image>
            <View style={styles.coverHead_box}>
              <Text style={styles.coverHead_Txt1}>개인 유전체 분석 서비스</Text>
              <Text style={styles.coverHead_Txt2}>Personal Genome Analysis Service</Text>
            </View>
            <View style={styles.coverHead_Box1}>
              <View style={styles.coverHead_Box2}>
                <Text style={styles.coverHead_Box2_TxtL}>의뢰기관</Text>
                <Text style={styles.coverHead_Box2_TxtR}>{it.vendor_name}</Text>
                <Text style={styles.coverHead_Box2_TxtL}>접수번호</Text>
                <Text style={styles.coverHead_Box2_TxtR}>{it.barcode_no}</Text>
              </View>
              <View style={styles.coverHead_Box2}>
                <Text style={styles.coverHead_Box2_TxtL}>이름</Text>
                <Text style={styles.coverHead_Box2_TxtR}>{it.user_name}</Text>
                <Text style={styles.coverHead_Box2_TxtL}>관리번호</Text>
                <Text style={styles.coverHead_Box2_TxtR}>{it.chart_no}</Text>
              </View>
              <View style={styles.coverHead_Box2}>
                <Text style={styles.coverHead_Box2_TxtL}>접수일</Text>
                <Text style={styles.coverHead_Box2_TxtR}>{it.jubsu_date}</Text>
                <Text style={styles.coverHead_Box2_TxtL}>생년월일</Text>
                <Text style={styles.coverHead_Box2_TxtR}>{it.birth}</Text>
              </View>
              <View style={styles.coverHead_Box2}>
                <Text style={styles.coverHead_Box2_TxtL}>보고일</Text>
                <Text style={styles.coverHead_Box2_TxtR}>{reviewDate(it.send_complete_day)}</Text>
                <Text style={styles.coverHead_Box2_TxtL}>채혈일</Text>
                <Text style={styles.coverHead_Box2_TxtR}>{it.pick_day}</Text>
              </View>
            </View>
            {/* <Image src="/img/report/report_cover_img.png" style={styles.coverHead_Img}></Image> */}
            <Image src="/img/report/report_cover_img.png" style={styles.coverHead_Img}></Image>
          </Page>

          {/* 빈페이지 */}
          {it.vendor_name === "부민병원" ? "" :
          <Page size="A4" style={styles.wrap}></Page>
          }

          {/* 1_전체결과요약 */}
          <Page size="A4" style={styles.wrap}>
            <Image src="/img/report/report_result_bg.png" style={styles.pg2Img} />
            <Text style={styles.product_name}>{it.product_name}</Text>
            <View style={styles.tit_back1}></View>
            <Text style={styles.product_tit}>고객님의 유전자 분석 요약 결과</Text>
            <View style={styles.side_box}>
              <View style={[styles.gh, styles.imgTxtBox]}>
                <Image src="/img/report/report_result_health.png" style={styles.gh_img} />
                <Text style={styles.gh_tit}>나의 유전자 신호</Text>
              </View>
              <View style={styles.gene_result_box}>
                <View style={styles.g_r_t}>
                  <Text>항목수</Text>
                  <Text style={styles.g_r_tR}>항목명</Text>
                </View>
                <View style={styles.rw_box}>
                  <View style={styles.rw_boxes}>
                    <Text style={[styles.rw_fi, styles.rw_fi_red]}>{it.warning}개</Text>
                    <Text style={styles.rw_sec}>{it.warning_disease}</Text>
                  </View>
                  <View style={styles.rw_boxes}>
                    <Text style={[styles.rw_fi, styles.rw_fi_yellow]}>{it.interest}개</Text>
                    <Text style={styles.rw_sec}>{it.interest_disease}</Text>
                  </View>
                  <View style={styles.rw_boxes}>
                    <Text style={[styles.rw_fi, styles.rw_fi_green]}>{it.nomal}개</Text>
                    <Text style={styles.rw_sec}>{it.normal_disease}</Text>
                  </View>
                </View>
                <Image src="/img/report/report_tatalresult_img.png" style={styles.tatal_img} />
              </View>

              <View style={styles.result_all_box}>
                <Text style={styles.result_all}>
                  유전적 요인 분석 결과, 주의가 필요한 항목은 <Text style={{ color: "#F8463B", fontSize: "12pt", fontWeight: 500 }}>{it.warning}개</Text>, 관심이 필요한 항목은{" "}
                  <Text style={{ color: "#F99C11", fontSize: "12pt", fontWeight: 500 }}>{it.interest}개</Text>입니다.
                  <Br />
                  질병 발생은 생활습관 등의 영향도 받으므로 평소 건강한 생활습관을 가지시기 바랍니다.
                </Text>
              </View>
              <Text style={styles.re_end}>
                ☞ 보다 자세한 결과는 <Text style={{ color: "#3428BB" }}>‘항목별 상세 결과’</Text> 페이지에서 확인하시기 바랍니다.
              </Text>
            </View>

            <BottomTxt>본 검사결과가 갖는 임상적 의미가 확립되지 않았으며, 이에 따르는 건강에 관련한 행위가 유용하다는 객관적 타당성이 아직 부족합니다.</BottomTxt>
          </Page>

          {/* 빈페이지 */}
          {it.vendor_name === "부민병원" ? "" :
          <Page size="A4" style={styles.wrap}></Page>
          }

          {/* 간지_1 */}
          <Page size="A4" style={styles.wrap}>
            <View style={styles.ganzi_txt1}>
              <Text style={styles.ganzi_txt2}>01</Text>
              <Text>개인 유전체 분석 <Br/>서비스 안내</Text>
            </View>
            <Image src="/img/report/report_subject_bg.png" style={styles.ganziImg}></Image>
            <BottomTxt>Copyright © 2024 HANCOM CARELINK, Inc, Ltd. All rights reserved.</BottomTxt>
          </Page>
          {/* 2_서비스소개 */}
          <Page size="A4" style={styles.wrap}>
            <Headlogo></Headlogo>
            <View style={[styles.imgTxtBox, styles.serviceInfo2_Box1]}>
              <Image src="/img/report/report_hancomGX_logo.png" style={styles.serviceInfo2_Box1_Img}></Image>
              <Text style={styles.serviceInfo2_Box1_Txt}>Genome Analysis Service</Text>
            </View>
            <Text style={styles.serviceInfo2_Txt}>
              <Text style={styles.boltTxt}>한컴 지엑스</Text>는 현재의 질병 유무를 진단하는 것이 아니라, 질병 발생에 대한 유전적 위험도를 분석하여 건강관리에 도움을 드리는 헬스케어 서비스 입니다.
            </Text>
            <Image src="/img/report/report_servieceInfo_bg.png" style={styles.serviceInfo2_Img}></Image>
            <View style={styles.serviceInfo2_Box2}>
              <View style={styles.serviceInfo2_Box3}>
                <Text style={styles.serviceInfo2_Box3_Txt1}>
                  유전체 분석 기술의 발달로 인간 개개인의 유전체를 모두 분석할 수 있게 되었고, 그 결과 개개인의 유전체는 99.9%가 동일하고, 0.1% 만이 다른 것으로 밝혀졌습니다. 0.1%의 차이가 외모 등과
                  같은 개인간의 차이뿐만 아니라 질병의 발생에도 영향을 미치는 것으로 알려져 있습니다.
                  <Br />
                  <Br />
                  <Br />
                  질병은 다양한 유전적 요인과 환경적 영향이 복합적으로 상호작용하여 발생합니다. 따라서 유전적 영향력이 큰 질병에서부터 환경적 영향이 큰 질병까지 질병마다 유전적 요인과 환경적 요인이
                  다르게 작용합니다.
                </Text>
                <Text style={styles.serviceInfo2_Box3_Txt2}>
                  유전체 분석 결과, 유전적으로 동일한 질병 발생 위험도를 보여도 개개인마다 식습관, 운동 및 활동량, 음주, 흡연 등의 환경적 요인에 따라 실질적인 질병 발생 가능성은 다릅니다. 그리고
                  유전적으로 질병 발생 위험도가 낮다고 하여 해당 질병이 발생되지 않는 것은 아닙니다. 환경적 요인과 아직 분석되지 않은 유전적 변이에 의해 영향을 받아 발병할 수 있습니다.
                  <Br />
                  <Br />
                  <Text style={styles.boltTxt}>HANCOM GX</Text>를 통해 개인별 유전적 질병 발생 위험도를 확인하고, 생활습관 개선을 통해 질병 발생 가능성을 최소화할 수 있습니다.
                </Text>
              </View>
            </View>
            <View style={[styles.imgTxtBox, styles.serviceInfo2_Box4]}>
              <View style={styles.serviceInfo2_Box4_Img_box}>
                <Image src="/img/report/report_servieceInfo_icn4_32px.png" style={styles.serviceInfo2_Box4_Img}></Image>
              </View>
              <Text style={styles.serviceInfo2_Box4_Txt}>
                본 검사결과가 갖는 임상적 의미가 확립되지 않았으며 이에 따르는 건강 관련 행위가 유용하다는 객관적 타당성이 아직 부족합니다. 유전 변이와 질병 발생과의 연관성에 대한 연구는 앞으로도
                활발하게 연구될 것이며 예측력 향상을 위해 바이오마커는 지속적으로 업데이트 될 예정입니다.
              </Text>
            </View>
          </Page>
          {/* 2-1_서비스소개 */}
          <Page size="A4" style={styles.wrap}>
            <Headlogo></Headlogo>
            <Text style={styles.serviceInfo21_Txt1}>
              한컴케어링크
              <Text style={styles.purpleboldTxt}> 개인 유전체 분석 서비스 </Text>
              개요
            </Text>
            <Text style={styles.serviceInfo21_Txt2}>나의 유전자를 바로 알고 질병에 대비해 보세요!</Text>
            <View style={styles.serviceInfo21_Box1}>
              <View style={styles.serviceInfo21_Box2}>
                <Image src="/img/report/report_servieceInfo_icn1_32px.png" style={styles.serviceInfo21_Box2_Img}></Image>
                <Text style={styles.serviceInfo21_Box2_Txt}>
                  <Text style={styles.boltTxt}>한컴케어링크의 개인 유전체 분석 서비스</Text>는 현재의 질병 유무를 진단하는 것이 아니라, 질병 발생에 대한 유전적 위험도를 분석하여 건강관리에 도움을
                  드리는 헬스케어 서비스 입니다.
                </Text>
              </View>
              <View style={styles.serviceInfo21_Box2}>
                <Image src="/img/report/report_servieceInfo_icn2_32px.png" style={styles.serviceInfo21_Box2_Img}></Image>
                <Text style={styles.serviceInfo21_Box2_Txt}>
                  질병은 다양한
                  <Text style={styles.boltTxt}> 유전적 요인과 환경적 영향의 복합적 상호작용</Text>이 원인이 되어 발생합니다. 따라서 질병에 따라 유전적 영향력이 큰 질병에서부터 환경적 영향이 큰
                  질병까지, 질병마다 유전적요인과 환경적 요인이 다르게 작용합니다.
                </Text>
              </View>
              <View style={styles.serviceInfo21_Box3}>
                <Image src="/img/report/report_servieceInfo_icn3_32px.png" style={styles.serviceInfo21_Box2_Img}></Image>
                <Text style={styles.serviceInfo21_Box2_Txt}>
                  유전체 분석 결과 유전적으로 동일한 질병 발생위험도를 가져도 식습관, 운동 및 활동량, 음주, 흡연 등의 환경적 요인에 의해
                  <Text style={styles.boltTxt}> 실질적인 질병 발생 가능성은 사람마다 다릅니다. </Text>
                  또한 유전적으로 질병 발생 위험도가 낮다고 하여 해당 질병이 발생되지 않는 것은 아닙니다.
                  <Text style={styles.boltTxt}> 환경적 요인 및 분석되지 않은 유전적 변이에 의해 영향을 받아 발병할 수 있습니다.</Text>
                </Text>
              </View>
            </View>
            <Image src="/img/report/report_servieceInfo_bg2.png" style={styles.serviceInfo21_Img}></Image>
          </Page>
          {/* 3_유전자검사의 이해 */}
          <Page size="A4" style={styles.wrap}>
            <Headlogo></Headlogo>
            <Text style={[styles.purpleboldTxt, styles.geneInfo3_Txt1]}>유전자검사의 이해</Text>
            <View style={styles.geneInfo3_Box1}>
              <View style={styles.geneInfo3_Box2}>
                <View style={styles.geneInfo3_Txt2}>
                  <View style={styles.tit_back}></View>
                  <Text>유전자란?</Text>
                </View>
                <Text style={styles.geneInfo3_Box2_Txt2}>
                  <Text style={styles.boltTxt}>유전자(gene)</Text>는 사람을 비롯한 동식물의 유전형질을 규정하는 인자, 즉 한 세대에서부터 다음 세대로 그 개체의 모든 생물학적 정보를 전달해 주는 물리적,
                  기능적인 단위로 생명현상의 가장 중요한 성분인 단백질을 만드는데 필요한 유전정보를 말하며, DNA의 염기배열로 표시됩니다.
                </Text>
              </View>
              <Image src="/img/report//report_servieceInfo_icn2_32px.png" style={styles.geneInfo3_Box1_Img}></Image>
              <View style={styles.geneInfo3_Box2}>
                <Text style={styles.geneInfo3_Box2_Txt1}>
                  <Text style={styles.boltTxt}>단일염기다형성</Text>
                  <Br />
                  (Single Nucleotide
                  <Br />
                  Polymorphism, SNP, 스닙)
                </Text>
                <Text style={styles.geneInfo3_Box2_Txt2}>
                  인간의 염색체를 구성하고 있는 약 30억 개의 염기서열 중 개인의 편차를 나타내는 한 개 또는 수십 개의 염기변이를 뜻합니다. 여러 사람들의 DNA 염기서열을 비교해보면 흔히 같은 위치에서
                  다른 염기가 발견되는데 이러한 다형성을 SNP 라고 합니다. SNP 는 대략 1,000 개의 염기마다 1 개 꼴로 나타나는데 사람의 경우 염기쌍이 약 30억 개이기 때문에 적어도 100만 개 의 변이를 갖고
                  있습니다. 인간은 99.9% 유전자가 일치하지만 0.1% 의 SNP 차이 때문에 외모 및 질병에 대한 위험도가 달라지게 됩니다. SNP 마커는 단지 두 종류의 대립인자로 존재하기 때문에 유전적 변별력은
                  제한적이지만, 존재하는 변이형이 워낙 많기 때문에 세밀한 유전적 연관성 규명에 높은 활용 가치를 가지고 있습니다. 따라서 SNP 마커는 복합형질의 유전적 원인 규명을 위한 연관성 연구
                  (association studies)에 주로 사용되고 있습니다.
                </Text>
              </View>
              <Image src="img/report/report_servieceInfo_icn5_32px.png" style={styles.geneInfo3_Box1_Img}></Image>
              <View style={styles.geneInfo3_Box2}>
                <Text style={styles.geneInfo3_Box2_Txt1}>
                  <Text style={styles.boltTxt}>전장 유전체 연관성 연구</Text>
                  <Br />
                  (Genome Wide Association
                  <Br />
                  Study, GWAS)
                </Text>
                <Text style={styles.geneInfo3_Box2_Txt2}>
                  인간의 질병 발생과 연관된 유전변이 특히 SNP를 규명하기 위해 질병발생과 관련한 병태생리학적 기전에 대한 고려 없이 유전체 전체에 존재하는 유전변이들을 분석하여 연관성이 높은 유전변이를
                  발굴하는 연구를 말합니다. 고집적 대용량 SNP chip 개발로 활성화되었으며 여러 가지 유전요인이 관여할 것으로 예상되는 만성질환 대사증후군 정신질환 등의 원인유전자 발굴에 크게 기여하게
                  되었습니다. 연관성 분석은 특정 질환군과 대조군의 유전자 발현의 차이 유전자 변이의 발현 빈도 차이 등에 대해 통계적 분석을 이용하여 유의성을 검증하는 과정을 뜻 합니다.
                </Text>
              </View>
              {/* <Image src="/img/report/report_dnaInfo_bg.png" style={styles.geneInfo3_Img}></Image> */}
            </View>
          </Page>
          {/* 3_1유전자검사의 이해 */}
          <Page size="A4" style={styles.wrap}>
            <Headlogo></Headlogo>
            <Image src="/img/report/report_servieceInfo2_bg2.png" style={styles.geneInfo31_Img}></Image>
            <View style={styles.geneInfo31_Box1}>
              <Image src="/img/report/report_servieceInfo_icn6_32px.png" style={styles.geneInfo31_Box1_Img1}></Image>
              <View style={styles.geneInfo31_Box2}>
                <Text style={styles.geneInfo31_Box2_Txt1}>
                  <Text style={styles.boltTxt}>유전성 질환</Text>
                  <Br />
                  (Mendelian disease 또는
                  <Br />
                  Monogenic disease)
                </Text>
                <Text style={styles.geneInfo31_Box2_Txt2}>
                  유전성 질환은 우리가 흔히 알고 있는 희귀난치성질환으로, 선천성 질환이 대부분 이라고 할 수 있습니다. 현재까지 약 4,000개~6,000개 이상의 질병이 부모로부 터 물려받은 단일 유전자(single
                  gene)의 돌연변이에 의해 발생된다고 알려져 있습니다. 대표적인 선천성 질환으로는 혈우병(Hemophilia), 마판 증후군(Marfan syndrome), 윌슨병(Wilson's disease), 고셔병(Gaucher's disease),
                  유전성 암 등이 있습니다.
                </Text>
              </View>
              <Image src="/img/report/report_servieceInfo_icn7_32px.png" style={styles.geneInfo31_Box1_Img1}></Image>
              <View style={styles.geneInfo31_Box2}>
                <Text style={styles.geneInfo31_Box2_Txt1}>
                  <Text style={styles.boltTxt}>복합 질환</Text>
                  <Br />
                  (Complex disease,
                  <Br />
                  Polygenic disease 또는
                  <Br />
                  Common disease)
                </Text>
                <Text style={styles.geneInfo31_Box2_Txt2}>
                  많은 사람이 겪는 질병(암, 당뇨병, 고혈압, 비만, 치매, 뇌졸중, 아토피 질환 등)들 은 다수의 유전자가 복합적으로 상호작용 또는 여러 유전자와 환경적인 요소들간 의 상호작용이 원인이 되어
                  질환으로 나타나게 됩니다. 이와 같은 복합 질환은 유 전적 요인이 큰 질병에서부터 환경적 요인이 큰 질병까지 질병마다 유전적 요인 과 환경적 요인이 발생에 미치는 영향은 다양합니다. 유전자
                  검사를 통해 개개인 의 질병 발생 위험 예측 정보를 알게 되면 조상으로부터 물려받은 개인의 유전적 요인은 바꿀 수 없지만 환경적 요인을 교종하여 질병의 발생을 최소화할 수 있습니다.
                </Text>
              </View>
              {/* <Image src="/img/report/backPlus.png" style={styles.geneInfo31_Box1_Img2}></Image> */}
            </View>
          </Page>
          {/* 4_결과확인방법 */}
          <Page size="A4" style={styles.wrap}>
            <Headlogo></Headlogo>
            <View style={styles.checkResult4_Box1}>
              <Text style={styles.res_back}></Text>
              <Text style={styles.checkResult4_Box1_Txt}>유전체 분석 결과, 이렇게 확인하세요!</Text>
            </View>
            <Text style={styles.checkResult4_Txt1}>
              어렵게만 느껴지는 결과지, 쉽게 알아볼 수 있게 설명해 드릴게요!
              <Br />
              질환별 유전체 분석 결과는 크게 주의 - 관심 - 보통 3단계로 구분해 드립니다.
              <Br />
              아래에서 각 단계별 질병 발생 위험도를 확인해 주세요.
            </Text>
            <View style={styles.checkResult4_Box2}>
              <Image src="/img/report/report_result_check-circle.png" style={styles.checkResult4_Box2_Img}></Image>
              <Text style={[styles.purpleboldTxt, styles.checkResult4_Box2_Txt]}>유전적 위험신호 3단계</Text>
            </View>
            <View style={styles.checkResult4_Box3}>
              <Image src="/img/report/report_servieceInfo2_info.png" style={styles.checkResult4_Box3_Img}></Image>
              <Text style={styles.checkResult4_Box3_Txt}>
                <Text style={styles.boltTxt}>유전적 질병 발생 위험도 : 높음</Text>
                <Br />
                유전 요인으로인한 질병 발생의 잠재적 위험이 높은 상태이며,
                <Br />
                식습관 조절, 운동과 같은 생활 패턴의 변화가 필요하며 주기적인
                <Br />
                건강검진으로 지속적인 건강관리가 요구됩니다.
                <Br />
                <Br />
                <Text style={styles.boltTxt}>질병 발생 위험도 : 보통</Text>
                <Br />
                유전 요인으로 인한 질병 발생의 잠재적 위험이 다소 있는 상태이며,
                <Br />
                전반적인 생활 패턴에 대한 관심과 조절이 요구됩니다.
                <Br />
                <Br />
                <Text style={styles.boltTxt}>질병 발생 위험도 : 낮음</Text>
                <Br />
                유전 요인으로 인한 질병 발생의 위험은 낮은 상태이나, 환경적
                <Br />
                요인에 의한 발병 가능성도 있으므로 건강한 생활 습관의 유지가
                <Br />
                요구됩니다.
              </Text>
            </View>
            <Image src="/img/report/report_result_back.png" style={styles.checkResult4_Img}></Image>
          </Page>
          {/* 4_1결과확인방법 */}
          <Page size="A4" style={styles.wrap}>
            <Image src="/img/report/report_hancomGX_logo_righttop.png" style={styles.headlogo} />
            <View style={[styles.imgTxtBox, styles.how_box]}>
              <Image src="/img/report/report_result_check-circle.png" style={styles.how_tit_img}></Image>
              <Text>상세 결과 보는 방법</Text>
            </View>
            <Text style={styles.how_tit}>다음의 분석결과 예시를 참고하시어 항목에 대한 분석결과를 확인해 보세요!</Text>

            <View style={styles.how_inner}>
              <Image src="/img/report/report_servieceInfo2_bg3.png" style={styles.how_img}></Image>
              <View style={styles.hb}>
                <View style={styles.hb_box}>
                  <Text style={styles.how_txt1}>① 유전적 요인을 반영한 나의 질병 발생 확률</Text>
                  <Text style={styles.how_txt2}>일반적인 한국인에게 각 질병에 대해서 알려진 유병률에 유전적 발생 위험도가 반영된 질병 발생 확률입니다.</Text>
                </View>
                <View style={styles.hb_box}>
                  <Text style={styles.how_txt1}>② 유전적 요인을 반영한 나의 상대적 질병 발생 위험도</Text>
                  <Text style={styles.how_txt2}>각 질병에 대해 분석된 유전적 요인의 종합적인 분석결과로, 일반적인 한국인의 질병 발생 위험도를 기준으로 한 상대적 위험도입니다.</Text>
                </View>
                <Text style={styles.how_txt1}>③ 유전적 요인을 반영한 한국인 내 나의 유전적 위험 정도</Text>
              </View>
              <Image src="/img/report/report_servieceInfo2_bg4.png"></Image>

              <View style={styles.how_table}>
                <View style={styles.how_row}>
                  <Text style={styles.how_col_1}>
                    유전자명
                    <Br />
                    <Text style={styles.how_col_1_span}>Gene</Text>
                  </Text>
                  <Text style={styles.how_col_2}>분석에 포함된 유전자의 이름</Text>
                </View>
                <View style={styles.how_row}>
                  <Text style={styles.how_col_1}>
                    대립인자
                    <Br />
                    <Text style={styles.how_col_1_span}>Allele</Text>
                  </Text>
                  <Text style={styles.how_col_2}>각 유전자 부위를 분석하여 나타날 수 있는 단일염기다형성의 결과, A, G, C, T의 4개의 염기 조합</Text>
                </View>
                <View style={styles.how_row}>
                  <Text style={styles.how_col_1}>
                    영향인자
                    <Br />
                    <Text style={styles.how_col_1_span}>Effect Allele</Text>
                  </Text>
                  <Text style={styles.how_col_2}>각 유전자 부위 중 질병에 대한 감수성에 영향을 주는 것으로 보고된 단일염기</Text>
                </View>
                <View style={styles.how_row}>
                  <Text style={styles.how_col_1}>
                    나의 유전형
                    <Br />
                    <Text style={styles.how_col_1_span}>Genotype</Text>
                  </Text>
                  <Text style={styles.how_col_2}>이중나선으로 되어 있는 DNA의 각 유전자 부위의 대립인자의 조합에 따라 3가지 유전형 중 하나로 나타날 수 있는 개인의 결과</Text>
                </View>
                <View style={styles.how_row}>
                  <Text style={styles.how_col_1}>
                    영향인자별 상대적 위험도
                    <Br />
                    <Text style={styles.how_col_1_span}>Relative Risk Rate</Text>
                  </Text>
                  <Text style={styles.how_col_2}>해당 영향인자를 보유한 경우 질병 발생에 미치는 위험도</Text>
                </View>
              </View>
            </View>
          </Page>
          {/* 사전안내 */}
          <Page size="A4" style={styles.wrap}>
            <Image src="/img/report/report_hancomGX_logo_righttop.png" style={styles.headlogo} />
            <Text style={styles.notice_tit}>유전자검사 사전안내</Text>

            <View style={styles.side_box}>
              <View style={styles.no_li_box}>
                <View style={[styles.no_txt]}>
                  <Image src="/img/report/akar-icons_check-box.png" style={styles.notice_li}></Image>
                  <Text>본 검사결과는 환경적 요인이 반영되지 않은 유전적 특성만을 확인한 것으로 현재 상태와는 상이할 수 있습니다</Text>
                </View>
                <View style={[styles.no_txt]}>
                  <Image src="/img/report/akar-icons_check-box.png" style={styles.notice_li}></Image>
                  <Text>본 검사결과는 생활 습관 개선을 위한 보조 데이터로 질병의 진단 및 치료 목적으로 사용할 수 없습니다</Text>
                </View>
                <View style={[styles.no_txt]}>
                  <Image src="/img/report/akar-icons_check-box.png" style={styles.notice_li}></Image>
                  <Text>본 검사결과가 갖는 임상적 의미가 확립되지 않았으며 이에 따르는 건강에 관련한 행위가 유용하다는 객관적 타당성이 아직 부족합니다</Text>
                </View>
                <View style={[styles.no_txt]}>
                  <Image src="/img/report/akar-icons_check-box.png" style={styles.notice_li}></Image>
                  <Text>본 검사의 예측력 향상을 위해 분석 마커는 지속적으로 업데이트 될 예정이며 이에 따라 동일인이라도 결과의 해석이 달라질 수 있습니다</Text>
                </View>
                <View style={[styles.no_txt]}>
                  <Image src="/img/report/akar-icons_check-box.png" style={styles.notice_li}></Image>
                  <Text>
                    본 검사 결과는 동일한 검사를 진행하여도 인종에 따라 도출되는 결과가 달라질 수 있습니다. 또한 동일인이라도 사용된 유전자의 종류 및 데이터베이스 분석 기준에 따라 타분석 기관의 결과와
                    다를 수 있습니다
                  </Text>
                </View>
                <View style={[styles.no_txt]}>
                  <Image src="/img/report/akar-icons_check-box.png" style={styles.notice_li}></Image>
                  <Text>본 검사결과는 '생명윤리 및 안전에 관한 법률'에 따라 유전 정보에 의한 교육, 고용, 승진 등 사회활동에 있어서 차별의 근거로 사용할 수 없습니다</Text>
                </View>
                <View style={[styles.no_txt]}>
                  <Image src="/img/report/akar-icons_check-box.png" style={styles.notice_li}></Image>
                  <Text>다른 법률에 특별한 규정이 있는 경우를 제외하고는 타인에게 유전자검사를 받도록 강요하거나 유전자검사의 결과를 제출하도록 강요할 수 없습니다</Text>
                </View>
                <View style={[styles.no_txt]}>
                  <Image src="/img/report/akar-icons_check-box.png" style={styles.notice_li}></Image>
                  <Text>본 검사의 결과지는 의뢰기관을 통해 검사대상자 본인 또는 지정된 수신인에게만 제공됩니다</Text>
                </View>
                <View style={[styles.no_txt]}>
                  <Image src="/img/report/akar-icons_check-box.png" style={styles.notice_li}></Image>
                  <Text>본 검사의 동의는 철회할 수 있으며 동의 철회 시 검체는 폐기 처리됩니다</Text>
                </View>
                <View style={[styles.no_txt]}>
                  <Image src="/img/report/akar-icons_check-box.png" style={styles.notice_li}></Image>
                  <Text>
                    본 검사에 사용된 검체는 결과 보고 후 14일 내 재검 및 추가 검사 동의 요청이 없으면 자동 폐기됩니다. 단, 검체가 폐기되는 경우에도 검체 폐기의 기록은 관리대장에 기록하여 관리합니다
                  </Text>
                </View>
                <View style={[styles.no_txt]}>
                  <Image src="/img/report/akar-icons_check-box.png" style={styles.notice_li}></Image>
                  <Text>본 검사 관련 기록, 유전자검사 동의서, 유전자검사 결과는 '생명윤리 및 안전에 관한 법률'에 따라 10년간 보관됩니다</Text>
                </View>
              </View>
            </View>

            {/* <Image src="/img/report/report_servieceInfo3_bg.png" style={styles.notice_img} /> */}
          </Page>
          {/* 간지_2 */}
          <Page size="A4" style={styles.wrap}>
            <View style={styles.ganzi_txt1}>
              <Text style={styles.ganzi_txt2}>02</Text>
              <Text>항목별 상세 결과</Text>
            </View>
            <Image src="/img/report/report_subject_bg.png" style={styles.ganziImg}></Image>
            <BottomTxt>Copyright © 2024 HANCOM CARELINK, Inc, Ltd. All rights reserved.</BottomTxt>
          </Page>
          {/* 항목별 결과 */}
          {/* <DiseaseResult /> */}
          {/* 암 종합정보 */}
          <DiseaseInfo chkData2={chkData2} />
          {/* 간지_3 */}
          <Page size="A4" style={styles.wrap}>
            <View style={styles.ganzi_txt1}>
              <Text style={styles.ganzi_txt2}>03</Text>
              <Text>유전체 분석 상세 정보</Text>
            </View>
            <Image src="/img/report/report_subject_bg.png" style={styles.ganziImg}></Image>
            <BottomTxt>Copyright © 2024 HANCOM CARELINK, Inc, Ltd. All rights reserved.</BottomTxt>
          </Page>
          {/* 분석 정보 */}
          <Page size="A4" style={styles.wrap}>
            <Image src="/img/report/report_hancomGX_logo_righttop.png" style={styles.headlogo} />
            <Text style={styles.analysis_tit}>분석정보</Text>
            <View style={[styles.imgTxtBox]}>
              <Image src="/img/report/report_result_health.png" style={styles.gh_img} />
              <Text style={styles.gh_tit}>검사자 정보</Text>
            </View>

            <View style={styles.analysis_table}>
              <View style={[styles.analysis_row, styles.an_b]}>
                <View style={styles.analysis_col_1}>
                  <Text>성명</Text>
                </View>
                <View style={styles.analysis_col_2}>
                  <Text>{it.user_name}</Text>
                </View>
                <View style={styles.analysis_col_3}>
                  <Text>의뢰명</Text>
                </View>
                <View style={styles.analysis_col_4}>
                  <Text>{it.product_name}</Text>
                </View>
              </View>
              <View style={styles.analysis_row}>
                <View style={styles.analysis_col_1}>
                  <Text>성별</Text>
                </View>
                <View style={styles.analysis_col_2}>
                  <Text>{it.gender}</Text>
                </View>
                <View style={styles.analysis_col_3}>
                  <Text>바코드</Text>
                </View>
                <View style={styles.analysis_col_4}>
                  <Text>{it.barcode_no}</Text>
                </View>
              </View>
            </View>

            <View style={[styles.imgTxtBox, styles.a_gh]}>
              <Image src="/img/report/report_result_health.png" style={styles.gh_img} />
              <Text style={styles.gh_tit}>실험 정보</Text>
            </View>

            <View style={styles.analysis_table}>
              <View style={[styles.analysis_row, styles.an_b]}>
                <View style={styles.analysis_col_1}>
                  <Text>검체접수일</Text>
                </View>
                <View style={styles.analysis_col_2}>
                  <Text>{it.jubsu_date}</Text>
                </View>
                <View style={styles.analysis_col_3}>
                  <Text>결과보고일</Text>
                </View>
                <View style={styles.analysis_col_4}>
                  <Text>{reviewDate(it.send_complete_day)}</Text>
                </View>
              </View>
              <View style={styles.analysis_row}>
                <View style={styles.analysis_col_1}>
                  <Text>검체유형</Text>
                </View>
                <View style={styles.analysis_col_2}>
                  <Text>{it.pick_type}</Text>
                </View>
                <View style={styles.analysis_col_3}>
                  <Text>검사방법</Text>
                </View>
                <View style={styles.analysis_col_4}>
                  <Text>Microarray</Text>
                </View>
              </View>
            </View>

            <View style={styles.an_gray_table}>
              <View style={[styles.gray_row, styles.gray_colorRow]}>
                <View style={styles.gray_col_1}>
                  <Text>DNA 총량 기준 1ug</Text>
                </View>
                <View style={styles.gray_col_2}>
                  <Text>DNA 분석 기준</Text>
                </View>
                <View style={styles.gray_col_3}>
                  <Text>검체의 적정성</Text>
                </View>
                <View style={styles.gray_col_4}>
                  <Text>비 고</Text>
                </View>
              </View>

              <View style={styles.gray_row}>
                <View style={styles.gray_col_2}>
                  <Text>pass</Text>
                </View>
                <View style={styles.gray_col_2}>
                  <Text>pass</Text>
                </View>
                <View style={styles.gray_col_3}>
                  <Text>pass</Text>
                </View>
                <View style={styles.gray_col_4}>
                  <Text> </Text>
                </View>
              </View>
            </View>
            <Text style={styles.an_txt}>※ 한컴케어링크의 모든 검사 항목은 정기적인 정도관리를 시행합니다.</Text>

            <View style={[styles.imgTxtBox, styles.an_box]}>
              <View style={styles.an_box_txt}>
                <Text style={styles.an_title}>분석기관 정보</Text>
                <View style={[styles.imgTxtBox, styles.an_mar]}>
                  <Text style={styles.an_title_txt}>기관명</Text>
                  <Text style={styles.an_title_txt2}>한컴케어링크</Text>
                </View>
                <View style={[styles.imgTxtBox, styles.an_mar]}>
                  <Text style={styles.an_title_txt}>주소</Text>
                  <Text style={styles.an_title_txt2}>경기도 성남시 분당구 대왕판교로 644번길 49 한컴타워 5층 </Text>
                </View>
                <View style={[styles.imgTxtBox, styles.an_mar]}>
                  <Text style={styles.an_title_txt}>전화번호</Text>
                  <Text style={styles.an_title_txt2}>1800-5270</Text>
                </View>
              </View>
              <Image src="/img/report/report_analysisInfo_center.png" style={styles.an_box_img}></Image>
            </View>

            <View style={[styles.imgTxtBox]}>
              <Image src="/img/report/report_result_health.png" style={styles.gh_img} />
              <Text style={styles.gh_tit}>확인</Text>
            </View>

            <View style={[styles.an_gray_table, styles.purple_table]}>
              <View style={[styles.gray_row, styles.purple_colorRow]}>
                <View style={styles.gray_col_1}>
                  <Text>검사자</Text>
                </View>
                <View style={styles.gray_col_2}>
                  <Text>검사책임자</Text>
                </View>
                <View style={styles.gray_col_3}>
                  <Text>검사실 책임자</Text>
                </View>
                <View style={styles.gray_col_4}>
                  <Text>담당의</Text>
                </View>
              </View>

              <View style={[styles.gray_row, styles.purple_row_1]}>
                <View style={styles.gray_col_1}>
                  <Image src="/img/report/report_analysisInfo_sign1.jpg" style={[styles.sign_img, styles.s_1]}></Image>
                </View>
                <View style={styles.gray_col_2}>
                  <Image src="/img/report/report_analysisInfo_sign2.png" style={[styles.sign_img, styles.s_2]}></Image>
                </View>
                <View style={styles.gray_col_3}>
                  <Image src="/img/report/report_analysisInfo_sign3.png" style={[styles.sign_img, styles.s_3]}></Image>
                </View>
                <View style={styles.gray_col_4}></View>
              </View>

              <View style={[styles.gray_row, styles.purple_b]}>
                <View style={styles.gray_col_2}>
                  <Text>서지우</Text>
                </View>
                <View style={styles.gray_col_2}>
                  <Text>최혜원</Text>
                </View>
                <View style={styles.gray_col_3}>
                  <Text>김정아</Text>
                </View>
                <View style={styles.gray_col_4}>
                  <Text> </Text>
                </View>
              </View>
            </View>

            <BottomTxt>본 검사결과가 갖는 임상적 의미가 확립되지 않았으며, 이에 따르는 건강에 관련한 행위가 유용하다는 객관적 타당성이 아직 부족합니다.</BottomTxt>
          </Page>
          {/* 분석 정보 end */}
          {/* 참고문헌 */}
          <Page size="A4" style={styles.wrap}>
            <Headlogo></Headlogo>
            <Text style={[styles.purpleboldTxt, styles.references_Txt]}>참고문헌</Text>
            <View style={styles.references_Box1}>
              <Text style={styles.references_Box2_Txt1}>
                Nat Genet. 2013 Jan;45(1):72-5 <Br />
                PLoS One. 2015 Oct 22;10(10) <Br />
                PLoS Genet. 2009 Jul;5(7):e1000564. <Br />
                Int J Mol Sci. 2016 Apr 18;17(4):586 <Br />
                J Steroid Biochem Mol Biol. 2007 Mar;103(3-5):799-802 <Br />
                Hum Genet. 2012 131(2):217-234 <Br />
                Nat Genet. 2013 Dec;45(12):1452-8 <Br />
                PLoS One. 2013 8(10):e76463 <Br />
                Nat Genet. 2012 Jan 22;44(3):319-22 <Br />
                Nat Genet. 2009;41(7):807-10 <Br />
                Nat Genet. 2009;41(9):996-1000 <Br />
                J Korean Med Sci. 2015 Aug;30(8):1101-9 <Br />
                Nat Genet. 2010;42(11):978-84 <Br />
                J Hum Genet. 2012;57(4):235-7 <Br />
                Nat Genet. 2014 Sep;46(9):989-93 <Br />
                Lancet. 2012;380(9844):815-23 <Br />
                Nature. 2007 Jun 7;447(7145):661-78 <Br />
                Nat Genet. 2011 43(4):333-338 <Br />
                Nat Genet. 2012 44(6):670-675 <Br />
                Nat Genet. 2013;45(8):918-22 <Br />
                Nat Genet. 2010 Sep;42(9):751-4 <Br />
                Nat Genet. 2012 44(8):900-903 <Br />
                Infect Genet Evol. 2015 Jul;33:72-6 <Br />
                PLoS Genet. 2011 Jul;7(7):e1002193 <Br />
                BMC Medical Genetics 2012, 13:27 <Br />
                Int J Mol Sci. 2014 Sep 29;15(10):17478-92 <Br />
                Hum Genet. 2012 131(9):1495-1505 <Br />
                Gastroenterology. 2013 144(4):799-807 <Br />
                PLoS One. 2013;8(4):e58618 <Br />
                Nat Genet. 2012 44(11):1182-1184 <Br />
                Thyroid. 2013 Dec;23(12):1532-40 <Br />
                Nat Genet. 2013;45(6):686-9 <Br />
                Nat Genet. 2013;45(6):680-5 <Br />
                PLoS One. 2014 Mar 17;9(3):e91790 <Br />
                Hum Mol Genet. 2014;23(5):1387-9 <Br />
                Asian Pac J Cancer Prev. 2014;15(1):123-7. <Br />
                PLoS Genet. 2011 Jun;7(6):e1002141 <Br />
                BMC Med Genet. 2014;15:53 <Br />
                Stroke. 2014 Jan;45(1):24-36 <Br />
                Nat Genet. 2009 41(3):334-341 <Br />
                Transl Oncol. 2008. 1(2):73-83. <Br />
                Nat Commun. 2015 Oct 7;6:8469 <Br />
                Nat Genet. 2010 42(10):893-896 <Br />
                Nature. 2007;447(7145):661-78. <Br />
                Blood. 2010 Dec 16;116(25):5688-97 <Br />
                Nat Genet. 2011 43(9):893-896
              </Text>
              <Text style={[styles.references_Box2_Txt1]}>
                Nat Genet. 2014. 46(6):533-542 <Br />
                Mol Psychiatry. 2016 Jan;21(1):108-17 <Br />
                Nat Genet. 2009 Mar;41(3):324-8 <Br />
                Nat Genet. 2010 Jul;42(7):604-7 <Br />
                Nat Genet. 2010;42(10):874-9 <Br />
                Nat Genet. 2008 Dec;40(12):1399-401 <Br />
                Nat Genet. 2010;42(9):764-7 <Br />
                Ann Neurol. 2012 Mar;71(3):370-84 <Br />
                Am J Hum Genet. 2011;89(3):446-50 <Br />
                Nat Genet. 2011 Mar 6;43(4):333-8 <Br />
                Cancer Epidemiol Biomarkers Prev. 2012 21(11):2048-2058 <Br />
                Nat Commun. 2015;6:6252 <Br />
                Virus Res. 2014 May 12;184:1-6 <Br />
                Nat Genet. 2011 43(9):887-892 <Br />
                Gut. 2011 60(6):799-805 <Br />
                J Natl Cancer Inst. 2010 Jul 7;102(13):972-81 <Br />
                PLoS Genet. 2013;9(3):e1003212 <Br />
                N Engl J Med. 2008 Dec 25;359(26):2767-77 <Br />
                Genes Chromosomes Cancer. 2002;35(3):219-31 <Br />
                Nat Genet. 2011 Mar 6;43(4):339-44 <Br />
                Nat Genet. 2011 43(4):339-344 <Br />
                Nat Genet. 2010 42(2):117-122 <Br />
                Nat Genet. 2010 42(9):751-754. <Br />
                Nat Genet. 2012 44(12):1330-1335 <Br />
                Hypertens Res. 2011 May;34(5):606-11 <Br />
                Circulation. 2011; 124:2855-2864 <Br />
                Nat Genet. 2009 Oct;41(10):1088-93 <Br />
                Nat Genet. 2013;45(4):362-70 <Br />
                Diabetes. 2012;61(2):531-541 <Br />
                Springerplus. 2014;3:599. <Br />
                Lancet Neurol. 2012;11(11):951-962 <Br />
                Acta Naturae. 2015 Jul-Sep;7(3):89-99 <Br />
                Nat Genet. 2012; 44(8): 890–894 <Br />
                Nat Genet. 2007 39(8):989-994 <Br />
                PLoS One. 2014 Apr 22;9(4):e95413 <Br />
                Nat Genet. 2010;42(10):880-4. <Br />
                Nat Genet. 2009 Jun;41(6):703-7 <Br />
                BMC Genomics. 2014 Feb 10;15:118 <Br />
                Stroke. 2014;45(1):24-36 <Br />
                Hum Mol Genet. 2014;23(1):239-246 <Br />
                Nat Genet. 2011 May;43(5):436-41 <Br />
                Diabetes. 2009 58(7):1690-1699 <Br />
                Nat Genet. 2010 Sep;42(9):781-5 <Br />
                Mol Psychiatry. 2012 Dec;17(12):1340-6 <Br />
                PLoS Genet. 2012;8(3):e1002548 <Br />
                Arch Neurol. 2008 Jan;65(1):45-53
              </Text>
            </View>
            <View style={styles.references_Box3}>
              <Image src="/img/report/report_servieceInfo_icn4_32px.png" style={styles.references_Box4_Img}></Image>
              <View style={styles.references_Box3_Txt}>
                <Text style={styles.references_Box3_Txt_in}>본 유전체분석 서비스는 해당 분야에서 세계적으로 권위있는 학술지에 보고된 연구결과를 기반으로 합니다.</Text>
                <Text style={styles.references_Box3_Txt_in}>
                  본 유전체분석 서비스는 각 항목별 분석 방법 및 결과, 그에 대한 해석은 해당 분야의 발전과 지속적으로 보고되는 연구결과에 따라 달라질 수 있습니다.
                </Text>
                <Text>본 유전체분석 서비스는 품질 향상을 위해 연구 및 보완 개발 등의 체계적인 품질관리체계를 운영합니다.</Text>
              </View>
            </View>
          </Page>
          
          {/* 광고자료 페이지 */}
          <Page size="A4" style={styles.addWrap}>
            <View style={styles.add_box}>
              <Image src="/img/report/report_advertise.png" style={styles.addPage}></Image>
            </View>
          </Page>

          {/* 이미지페이지추가 */}
          <Page size="A4" style={styles.wrap}>
            <View style={styles.imgpage_box}>
              <Image src="/img/report/report_carehealth_img.png" style={styles.IMGpage}></Image>
            </View>
          </Page>

          {/* 빈페이지 */}
          {it.vendor_name === "부민병원" ? "" :
          <Page size="A4" style={styles.wrap}></Page>
          }

          {/* 마지막페이지 */}
          <Page size="A4" style={styles.wrap}>
            <View style={styles.lastPage_Box1}>
              <View style={styles.lastPage_Box2}>
                <Image src="/img/report/logo.png" style={styles.lastPage_Box2_Img}></Image>
                <Text style={styles.lastPage_Box2_Txt}>
                  한컴케어링크는 건강검진 결과 및 생활습관 등<Br />
                  개인건강기록(PHR)을 AI가 분석하여 맞춤형 검진 추천과
                  <Br />
                  다양한 헬스케어 솔루션을 제공합니다.
                  <Br />
                  <Br />
                  한컴케어링크는 유전체 및 마이크로바이옴 분석 결과를 기반으로
                  <Br />
                  질병 발생 가능성을 예측하고, 건강검진 결과 등과 연계한
                  <Br />
                  융합 데이터를 생성하여 차별성을 확보합니다.
                  <Br />
                  <Br />
                  한컴케어링크는 AI와 메타버스 등 IT 기술을 접목하여
                  <Br />
                  누구나 건강하게 생활하도록 디지털 헬스케어를 이끌어가겠습니다.
                  <Br />
                  <Br />
                </Text>
                <Image src="/img/report/report_lastpage_text.png" style={styles.lastPage_Box2_Txt_img}></Image>
              </View>
              <View style={styles.lastPage_Box3}>
                <Image src="/img/report/report_lastPage_icn1.png" style={styles.lastPage_Box3_Img}></Image>
                <Image src="/img/report/report_lastPage_icn2.png" style={styles.lastPage_Box3_Img}></Image>
                <Image src="/img/report/report_lastPage_icn3.png" style={styles.lastPage_Box3_Img}></Image>
              </View>
            </View>
          </Page>

          {/* 전체결과요약 일송학원강남성심병원 */}
          {it.vendor_name !== "일송학원강남성심병원" ? "" :
            sliceArray?.map((firstItem, firstIdx)=>(
              <Page size="A4" style={styles.ilsong_wrap} key={firstIdx}>
                <Image src="/img/report/report_result_bg.png" style={styles.pg2Img_gangnam} />
                <Image src="/img/report/report_hancomGX_logo.png" style={styles.pg2Img_ilsong_1} />
                <Text style={styles.product_name_gangnam}>{it.product_name}</Text>
                <View style={styles.tit_back1_gangnam}></View>
                <Text style={styles.product_tit_gangnam}>고객님의 유전체 분석 결과</Text>

                {/* 일자 */}
                <View style={styles.day_box_table}>
                  <View style={styles.day_row}>
                    <View style={[styles.day_col_1]}>
                      <Text>채혈일</Text>
                    </View>
                    <View style={[styles.day_col_2]}>
                      <Text>{it.pick_day}</Text>
                    </View>
                    <View style={[styles.day_col_1, styles.add_left_border]}>
                      <Text>접수일</Text>
                    </View>
                    <View style={[styles.day_col_2]}>
                      <Text>{it.jubsu_date}</Text>
                    </View>
                    <View style={[styles.day_col_1, styles.add_left_border]}>
                      <Text>보고일</Text>
                    </View>
                    <View style={[styles.day_col_2]}>
                      <Text>{reviewDate(it.send_complete_day)}</Text>
                    </View>
                  </View>
                </View>

                {/* 정보 */}
                <View style={styles.ilsong_box_table}>
                  <View style={styles.info_row}>
                    <View style={[styles.info_col, styles.ilsong_col_1]}>
                      <Text>의뢰기관</Text>
                    </View>
                    <View style={[styles.info_col, styles.ilsong_col_2]}>
                      <Text>{it.vendor_name}</Text>
                    </View>
                    <View style={[styles.info_col, styles.ilsong_col_1, styles.add_left_border]}>
                      <Text>담당의사</Text>
                    </View>
                    <View style={[styles.info_col, styles.ilsong_col_2]}>
                      <Text></Text>
                    </View>
                  </View>
                  <View style={styles.info_row}>
                    <View style={[styles.info_col, styles.ilsong_col_1]}>
                      <Text>이름</Text>
                    </View>
                    <View style={[styles.info_col, styles.ilsong_col_2,]}>
                      <Text>{it.user_name}</Text>
                    </View>
                    <View style={[styles.info_col, styles.ilsong_col_1, styles.add_left_border]}>
                      <Text>생년월일</Text>
                    </View>
                    <View style={[styles.info_col, styles.ilsong_col_2]}>
                      <Text>{it.birth}</Text>
                    </View>
                  </View>
                  <View style={styles.ilsong_row_none}>
                    <View style={[styles.info_col, styles.ilsong_col_1]}>
                      <Text>접수번호</Text>
                    </View>
                    <View style={[styles.info_col, styles.ilsong_col_2]}>
                      <Text>{it.barcode_no}</Text>
                    </View>
                    <View style={[styles.info_col, styles.ilsong_col_1, styles.add_left_border]}>
                      <Text>관리번호</Text>
                    </View>
                    <View style={[styles.info_col, styles.ilsong_col_2]}>
                      <Text>{it.chart_no}</Text>
                    </View>
                  </View>
                </View>

                <View style={[styles.gh_half, styles.imgTxtBox]}>
                  <Image src="/img/report/report_result_health.png" style={styles.gh_img}/>
                  <Text style={styles.gh_tit}>유전체 분석 결과</Text>
                </View>

                {/* 유전체 분석 테이블 */}
                <View style={styles.ilsong_box_table}>
                  <View style={styles.ilsong_col_wrapper}>
                    <View style={[styles.ilsong_col, styles.ilsong_no_col]}>
                          <Text>No</Text>
                    </View>
                    <View style={[styles.ilsong_col, styles.ilsong_deases_col]}>
                          <Text>질환명</Text>
                    </View>
                    <View style={[styles.ilsong_col, styles.ilsong_danger_col]}>
                        <View style={[styles.ilsong_half_center, styles.ilsong_genetic_col]}>
                          <Text>유전적 위험도</Text>
                        </View>
                        <View style={[styles.ilsong_bottom_col]}>
                          <View style={[styles.ilsong_half_center, styles.danger_result]}>
                            <Text>결과</Text>
                          </View>
                          <View style={[styles.ilsong_half_center, styles.danger_step]}>
                            <Text>단계</Text>
                          </View>
                        </View>
                    </View>
                    <View style={[styles.ilsong_col, styles.ilsong_prevalence_col]}>
                        <View style={[styles.ilsong_half_center, styles.ilsong_genetic_col]}>
                          <Text>유병률</Text>
                        </View>
                        <View style={[styles.ilsong_bottom_col]}>
                          <View style={[styles.ilsong_half_center, styles.prevalence_average]}>
                            <Text>한국인 평균</Text>
                          </View>
                          <View style={[styles.ilsong_half_center, styles.prevalence_expectations]}>
                            <Text>나의 예상 유병률</Text>
                          </View>
                        </View>
                    </View>
                  </View>

                  {/* 테이블 로우 */}
                    {firstItem.map((secondItem, secondIdx)=>{
                      const isLast = secondIdx === firstItem.length - 1;
                      return(
                        <View style={isLast ? styles.ilsong_row_wrapper_none : styles.ilsong_row_wrapper} key={secondIdx}>
                            <View style={[styles.ilsong_row_info, styles.ilsong_no_row]}>
                                <Text>{secondItem.index}</Text>
                            </View>
                            <View style={[styles.ilsong_row_info, styles.ilsong_deases_row]}>
                                <Text>{secondItem.disease}</Text>
                            </View>
                            <View style={[styles.ilsong_row_info, styles.ilsong_result_row]}>
                                <Text>{secondItem.prevalence}</Text>
                            </View>
                            <View style={[styles.ilsong_row_info, styles.ilsong_step_row]}>
                              {
                                secondItem.grp === 1 ? <Text style={styles.grp_l}>보통</Text>
                              : secondItem.grp === 2 ? <Text style={styles.grp_a}>관심</Text>
                              : secondItem.grp === 3 ? <Text style={styles.grp_h}>주의</Text>
                              : <td></td>
                              }
                            </View>
                            <View style={[styles.ilsong_row_info, styles.ilsong_average_row]}>
                                <Text>{korAvg(secondItem.kor_avg)}%</Text>
                            </View>
                            <View style={[styles.ilsong_row_info, styles.ilsong_expectations_row]}>
                                <Text>{myAvg(secondItem.kor_avg, secondItem.prevalence)}%</Text>
                            </View>
                        </View>
                    )})}

                  
                </View>

                {/* 설명서 */}
                <View style={styles.ilsong_description_wrapper}>
                  <View style={styles.ilsong_description_block}>
                    <Text style={styles.ilsong_description_title}>•︎ 위험도</Text>
                    <Text style={styles.ilsong_description_contents}>각 질병에 대해 분석된 유전적 요인의 종합적인 분석결과로, 일반적인 한국인의 질병 발생 위험도를 기준으로 한 상대적 위험도 입니다.</Text>
                  </View>
                  <View style={styles.ilsong_description_block}>
                    <Text style={styles.ilsong_description_title}>•︎ 나의 예상 유병률</Text>
                    <Text style={styles.ilsong_description_contents}>일반적인 한국인에게 각 질병에 대해서 알려진 유병률에 유전적 발생 위험도가 반영된 질병 발생 확률입니다.</Text>
                  </View>
                </View>

                {/* 푸터 */}
                <Image src="/img/report/logo.png" style={styles.bottomImg}></Image>
                <Text style={styles.re_bottom}>
                  경기도 성남시 분당구 대왕판교로 644번길 49 한컴타워 5층 <Text style={styles.re_in}>Tel 1800-5270</Text>
                </Text>
              </Page>
          ))}
        </Document>
      )})}
    </>
  );
};
export default ReportDocument;
